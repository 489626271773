import React from "react";

export default function Settings({
  settingsModal,
  setSettingsModal,
  darkMode,
  setDarkMode,
  speechToPrompt,
  setSpeechToPrompt,
  userLevel,
}) {
  const handleClose = () => {
    localStorage.setItem("darkMode", darkMode);
    localStorage.setItem("speechToPrompt", speechToPrompt);
    setSettingsModal(false);
  };

  return (
    <>
      {settingsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg w-full max-w-md p-6 transform transition-all duration-300 ease-in-out">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Settings
              </h2>
              <button
                onClick={handleClose}
                className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              >
                ✕
              </button>
            </div>

            <div className="space-y-6">
              <Toggle
                label="Dark Mode"
                isEnabled={darkMode}
                onToggle={() => setDarkMode(!darkMode)}
              />
              {userLevel !== "free" && (
                <Toggle
                  label="Speech to Prompt"
                  isEnabled={speechToPrompt}
                  onToggle={() => setSpeechToPrompt(!speechToPrompt)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Toggle({ label, isEnabled, onToggle }) {
  return (
    <div className="flex items-center justify-between py-2">
      <span className="text-gray-700 dark:text-gray-200">{label}</span>
      <button
        onClick={onToggle}
        className={`relative inline-flex items-center h-6 w-11 rounded-full transition-colors duration-300 ease-in-out ${
          isEnabled ? "bg-green-500" : "bg-gray-300 dark:bg-gray-600"
        }`}
      >
        <span
          className={`inline-block w-5 h-5 transform bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out ${
            isEnabled ? "translate-x-5" : "translate-x-0"
          }`}
        />
      </button>
    </div>
  );
}
