import React, { useState, useEffect } from "react";

const LoadingText = ({ text, isLoading }) => {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (isLoading) {
      if (index < text.length) {
        timeout = setTimeout(() => {
          setDisplayText((prev) => prev + text[index]);
          setIndex(index + 1);
        }, 50);
      } else {
        timeout = setTimeout(() => {
          setDisplayText("");
          setIndex(0);
        }, 100);
      }
    } else {
      setDisplayText("");
      setIndex(0);
    }

    return () => clearTimeout(timeout);
  }, [index, text, isLoading]);

  return <div style={{ minHeight: "40px" }}>{displayText}</div>;
};

export default LoadingText;
