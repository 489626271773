import React from "react";
import AccountantCard from "./AccountantCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AllAccountants = ({
  currentAccountants,
  handleSelectAccountant,
  loadingId,
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      {currentAccountants ? (
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center md:text-left">
          All Accountants
        </h2>
      ) : (
        <></>
      )}
      {currentAccountants?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full">
          {currentAccountants?.map((accountant, index) => (
            <AccountantCard
              key={index}
              accountant={accountant}
              handleSelectAccountant={handleSelectAccountant}
              loadingId={loadingId}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-64 w-full bg-gray-100 rounded-lg p-6">
          <div className="text-3xl font-semibold text-gray-700 mb-4">
            No Accountants Found
          </div>
          <p className="text-gray-500 text-lg mb-4">
            Try adjusting your filters or check back later for available
            accountants.
          </p>
          <img
            src="https://bethaibucket.s3.us-east-1.amazonaws.com/images/people-group-solid.svg"
            alt="No Accountants Icon"
            className="h-16 w-16 text-gray-400"
          />
        </div>
      )}
    </div>
  );
};

export default AllAccountants;
