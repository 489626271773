import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function FeedbackNegative({ setShowToolkit }) {
  const [others, setOthers] = useState(false);
  const [othersText, setOthersText] = useState("");

  const handleOther = () => {
    setOthers((prev) => !prev);
    if (others) {
      setOthersText("");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="relative bg-white rounded-lg shadow-2xl p-6 w-full max-w-md mx-4 md:mx-0">
        <button
          onClick={() => setShowToolkit(false)}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          How can we improve?
        </h2>

        <ul className="space-y-4">
          {[
            "Content not helpful",
            "Too slow to respond",
            "Too complex to understand",
          ].map((item, index) => (
            <li key={index} className="flex items-center">
              <input
                type="checkbox"
                id={`feedback-${index}`}
                className="h-5 w-5 text-blue-600 border-gray-300 rounded-full focus:ring-blue-500"
              />
              <label
                htmlFor={`feedback-${index}`}
                className="ml-3 text-gray-700"
              >
                {item}
              </label>
            </li>
          ))}

          <li className="flex flex-col">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="feedback-other-issues"
                checked={others}
                onChange={handleOther}
                className="h-5 w-5 text-blue-600 border-gray-300 rounded-full focus:ring-blue-500"
              />
              <label
                htmlFor="feedback-other-issues"
                className="ml-3 text-gray-700"
              >
                Other issues
              </label>
            </div>
            {others && (
              <textarea
                value={othersText}
                onChange={(e) => setOthersText(e.target.value)}
                placeholder="Please describe your concern..."
                className="mt-3 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-gray-700 dark:text-gray-100 resize-none"
                rows="3"
              />
            )}
          </li>
        </ul>

        <div className="flex justify-end mt-6 space-x-3">
          <button
            onClick={() => setShowToolkit(false)}
            className="px-5 py-2 text-sm font-semibold text-black bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => setShowToolkit(false)}
            className="px-5 py-2 text-sm font-semibold text-white bg-green-600 rounded-md hover:bg-green-700"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
