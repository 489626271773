import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowUpLeft } from "react-icons/fi";
import { scrollToTop } from "../../utils/navigation";

export default function FeaturesPage() {
  const featuresRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleSee = () => {
    const offsetTop = featuresRef.current?.offsetTop;
    window.scrollTo({
      top: offsetTop - 100,
      behavior: "smooth",
    });
  };

  const handleClick = () => {
    scrollToTop();
    navigate("/signup");
  };

  return (
    <>
      <main className="relative overflow-hidden font-sans">
        <section className="h-screen flex justify-center items-center bg-gradient-to-b from-blue-600 to-green-500 text-white text-center px-4 sm:px-8 relative">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-64 h-64 md:w-96 md:h-96 rounded-full bg-gradient-to-r from-blue-400 to-green-400 opacity-30 blur-lg animate-pulse" />
          </div>

          <div
            className="space-y-6 transform transition duration-500 ease-in-out relative z-10"
            data-aos="fade-up"
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold max-w-4xl leading-tight animate-text-glow">
              AI-Powered.
              <br /> Human-Driven.
              <br /> Accounting Evolved.
            </h1>
            <p
              className="text-sm sm:text-lg md:text-xl lg:text-2xl opacity-90 max-w-2xl mx-auto font-light"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              Over 3000 Questions Answered Instantly.
            </p>
            <button
              onClick={handleSee}
              className="mt-8 px-6 py-3 sm:px-8 sm:py-4 bg-white text-blue-500 font-semibold rounded-full shadow-lg transform hover:scale-105 hover:shadow-2xl transition ease-in-out duration-300"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              See More
            </button>
          </div>

          <div
            className="absolute bottom-6 right-6 sm:bottom-10 sm:right-10 md:bottom-12 md:right-12 text-3xl sm:text-4xl md:text-5xl text-white opacity-80 transform hover:scale-110 transition duration-500 ease-in-out"
            data-aos="fade-left"
            data-aos-delay="800"
          >
            <FontAwesomeIcon icon={faRobot} className="animate-spin-slow" />
          </div>
        </section>

        <section
          ref={featuresRef}
          className="min-h-screen bg-gray-200 text-white flex flex-col items-center justify-center px-6 py-10 sm:px-8 space-y-10"
        >
          <div
            className="flex flex-col lg:flex-row items-center max-w-5xl w-full space-y-8 lg:space-y-0 lg:space-x-12 transform transition duration-500 ease-in-out"
            data-aos="fade-right"
          >
            <div className="lg:w-5/12 w-full transform hover:scale-105 transition duration-300">
              <img
                src="/rsrch.jpg"
                className="object-cover w-full h-80 lg:h-[22rem] rounded-lg shadow-2xl"
                alt="Research Background"
              />
            </div>

            <div
              className="lg:w-7/12 w-full backdrop-blur-lg bg-black bg-opacity-70 p-6 sm:p-10 rounded-lg shadow-xl space-y-6 transition-transform transform hover:scale-105 duration-500 ease-in-out"
              data-aos="fade-left"
            >
              <h2 className="text-3xl sm:text-4xl font-semibold text-center tracking-wide">
                Instant Research
              </h2>
              <p className="text-base sm:text-lg font-light leading-relaxed max-w-xl mx-auto text-center">
                Ask for solutions for Tax, Accounting, Audit, and Licensing in
                seconds.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={(e) => {
                    navigate("/features/instant-research");
                    window.scrollTo(0, 0);
                  }}
                  className="mt-4 sm:mt-6 px-6 sm:px-8 py-3 sm:py-4 bg-white text-blue-500 font-semibold rounded-full shadow-lg transform hover:scale-105 hover:shadow-2xl transition ease-in-out duration-300"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col-reverse lg:flex-row items-center max-w-5xl w-full  lg:space-x-12 transform transition duration-500 ease-in-out"
            data-aos="fade-left"
          >
            <div
              className="lg:w-7/12 w-full backdrop-blur-lg bg-black bg-opacity-70 p-6 sm:p-10 rounded-lg shadow-xl space-y-6 transition-transform transform hover:scale-105 duration-500 ease-in-out"
              data-aos="fade-right"
            >
              <h2 className="text-3xl sm:text-4xl font-semibold text-center tracking-wide">
                Quick Email Response
              </h2>
              <p className="text-base sm:text-lg font-light leading-relaxed max-w-xl mx-auto text-center">
                Provide a brief prompt and let B.E.T.H. draft a well-crafted
                email, whether for a business proposal, business pitch, or
                response to a client inquiry.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={(e) => {
                    navigate("/features/email-response");
                    window.scrollTo(0, 0);
                  }}
                  className="mt-4 sm:mt-6 px-6 sm:px-8 py-3 sm:py-4 bg-white text-blue-500 font-semibold rounded-full shadow-lg transform hover:scale-105 hover:shadow-2xl transition ease-in-out duration-300"
                >
                  Learn More
                </button>
              </div>
            </div>

            <div className="lg:w-5/12 w-full transform hover:scale-105 transition duration-300 mb-8 sm:mb:0">
              <img
                src="/qer.jpg"
                className="object-cover w-full h-80 lg:h-[22rem] rounded-lg shadow-2xl"
                alt="Email Response"
              />
            </div>
          </div>
        </section>

        <section className="h-screen bg-gradient-to-r from-blue-500 to-green-400 flex items-center justify-center text-white text-center p-4 sm:p-10 relative">
          <div
            className="space-y-8 transform hover:scale-105 transition duration-500 ease-in-out"
            data-aos="fade-up"
          >
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold">
              Ready to Transform Your Accounting?
            </h2>
            <p className="text-md sm:text-lg font-light max-w-xl mx-auto opacity-90">
              Discover the full potential of intelligent finance solutions with
              seamless integration and enhanced security.
            </p>
            <button
              onClick={() => handleClick()}
              className="px-8 sm:px-10 py-3 sm:py-4 bg-white text-blue-500 font-semibold rounded-full shadow-xl hover:bg-gray-100 hover:scale-105 transform transition ease-in-out duration-300"
            >
              Get Started
            </button>
          </div>
        </section>
        <aside className="fixed left-6 bottom-6">
          <Link
            to="/"
            onClick={scrollToTop}
            className=" bg-white rounded-lg shadow-lg p-4 flex items-center space-x-2 hover:bg-gray-100 transition duration-300"
          >
            <FiArrowUpLeft className="text-gray-600" />
            <span className="text-gray-600">Back to Home</span>
          </Link>
        </aside>
      </main>
    </>
  );
}
