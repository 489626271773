//will trigger if eventsource have an error
const eventError = ({ index, message, messages }) => {
  return (
    <>
      {message.role === "assistant" && index === messages.length - 1 && (
        <p className="text-red-600 font-bold">
          An error occurred. Please check your connection and try again.
        </p>
      )}
    </>
  );
};

export default eventError;
