import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/HomePage/Home";
import Pricing from "./pages/PricingPage/Pricing";
import SignIn from "./pages/SignInPage/SignIn";
import ForgotPassword from "./pages/ForgotPasswordPage/ForgotPassword";
import AboutUs from "./pages/AbousUsPage/AboutUs";
import SignUp from "./pages/SignUpPage/SignUp";
import ResetPassword from "./pages/ResetPasswordPage/ResetPassword";
import AdminDashboard from "./pages/AdminDashboardPage/AdminDashboard";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import { MicrosoftAuthProvider } from "./utils/MicrosoftAuthProvider";
import EmailActivation from "./pages/EmailActivationPage/EmailActivation";
import { AuthProvider, useAuth } from "./utils/AuthProvider";
import ProtectedRoute from "./utils/ProtectedRoute";
import NotFound from "./pages/NotFoundPage/NotFound";
import Footer from "./components/Footer/Footer";
import UserPage from "./pages/UserPage/UserPage";
import ReactGA from "react-ga4";
import TawkTo from "./utils/TawkTo";
import Sources from "./pages/SourcesPage/Sources";
import PaymentSuccess from "./pages/UserPage/PaymentSuccess";
import PaymentFail from "./pages/UserPage/PaymentFail";
import FindAccountant from "./pages/FindAccountant/FindAccountant";
import AccountantProfile from "./pages/FindAccountant/components/AccountantProfile";
import FeaturesPage from "./pages/FeaturesPage/FeaturesPage";
import InstantResearch from "./pages/FeaturesPage/pages/InstantResearch";
import EmailResponse from "./pages/FeaturesPage/pages/EmailReponse";
import Terms from "./components/Terms/Terms";
import PrivacyPolicyModal from "./components/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (!ReactGA.isInitialized) {
      ReactGA.initialize("G-RVSGB8C8Y7", { debug: true });
    }
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}

function AppContent() {
  const { token } = useAuth();
  usePageTracking();

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route
        path="/signin"
        element={token ? <Navigate to="/app" replace /> : <SignIn />}
      />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/user/reset-password" element={<ResetPassword />} />
      <Route path="/email/forgot-password" element={<ForgotPassword />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/email/otp/" element={<EmailActivation />} />
      <Route path="/sources" element={<Sources />} />
      <Route path="/payment/success" element={<PaymentSuccess />} />
      <Route path="/payment/fail" element={<PaymentFail />} />
      <Route path="/accountant" element={<FindAccountant />} />
      <Route path="/accountant/profile/:id" element={<AccountantProfile />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/features/instant-research" element={<InstantResearch />} />
      <Route path="/features/email-response" element={<EmailResponse />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />

      <Route
        path="/app"
        element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-page"
        element={
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App() {
  return (
    <MicrosoftAuthProvider>
      <Router>
        <TawkTo />
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </MicrosoftAuthProvider>
  );
}

export default App;
