import React from "react";
import Navbar from "../../components/NavBar/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import MailTo from "../../utils/MailTo";
import Footer from "../../components/Footer/Footer";
import { motion } from "framer-motion";

export default function AboutUs() {
  return (
    <div className="bg-gray-50 min-h-screen font-sans text-gray-900">
      <Navbar />

      <section className="relative w-full h-screen flex items-center justify-center bg-gradient-to-r from-green-700 via-green-400 to-green-700">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className="text-center bg-black bg-opacity-40 p-8 rounded-lg max-w-xl mx-auto"
        >
          <h1 className="text-5xl sm:text-6xl font-bold text-white mb-4">
            Welcome to BETH AI
          </h1>
          <p className="text-xl sm:text-2xl text-gray-100">
            Discover the future of Accounting with AI
          </p>
        </motion.div>
      </section>

      <main className="container mx-auto py-16 px-4 sm:px-8">
        <motion.section className="text-center mb-16" data-aos="fade-up">
          <h1 className="text-4xl font-bold text-[#66B539] mb-6">About Us</h1>
          <p className="text-lg text-gray-700 leading-8 max-w-3xl mx-auto">
            We are committed to driving financial resilience and community
            growth across the Philippines. We believe that by providing
            transparent, accessible, and innovative accounting solutions, we can
            empower every entrepreneur to achieve success, foster local economic
            development, and contribute to a sustainable future.
            <br />
            <br />
            At ai.babylon2k.org, we blend cutting-edge AI technology with human
            insight to deliver intelligent solutions for tax, accounting, and
            audit. Whether you're a startup in Manila or managing finances from
            a remote village, our platform is designed to simplify your
            workflows, keep you compliant, and help you focus on what truly
            matters—growing your business.
          </p>
        </motion.section>

        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mb-16 text-center">
          {[
            {
              title: "Our Purpose",
              content:
                "We are revolutionizing accounting by going beyond the numbers. Through our platform, we empower impactful businesses, whether they’re in bustling city centers or the most remote areas. Our core purpose: drive organization’s success, foster community growth, and champion a sustainable future. Together, let’s create success stories that truly make a difference.",
            },
            {
              title: "Our Mission",
              content: `
        <p class="text-lg text-gray-700 leading-7 mb-2"> Our mission is to provide every organization with: </p>
        <p class="text-lg text-gray-700 leading-7 text-left">
          (i) immediate access to professional accountants <br />
          (ii) optimize financial costs <br />
          (iii) mitigate risks <br />
          —allowing them to focus on what truly matters: <strong> growth</strong> and <strong>success</strong>.
        </p>`,
            },
            {
              title: "Our Vision",
              content:
                "We envision a future where every entrepreneur or organization, irrespective of their location, has access to fair, efficient, and comprehensive business support",
            },
          ].map((value, index) => (
            <motion.div
              key={index}
              className="bg-white p-8 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              // transition={{ duration: 0.7, delay: index * 0.3 }}
            >
              <h2 className="text-3xl font-bold mb-4 text-[#66B539]">
                {value.title}
              </h2>
              <div
                className="text-lg text-gray-700 leading-7"
                dangerouslySetInnerHTML={{ __html: value.content }}
              />
            </motion.div>
          ))}
        </section>

        <motion.section
          className="text-center bg-gradient-to-r from-green-100 to-green-300 p-12 rounded-lg shadow-md mb-16"
          data-aos="zoom-in"
        >
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            Join Our Group
          </h2>
          <p className="text-xl text-gray-700 mb-6">
            Get involved in our community and be part of the change!
          </p>
          <a
            href="https://www.facebook.com/groups/taxsatori/?ref=share&mibextid=JOZb8W"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-green-600 text-white text-lg font-semibold py-3 px-6 rounded-lg hover:bg-green-700 transition-transform transform hover:scale-105"
          >
            Join Now
          </a>
        </motion.section>

        <section className="bg-white p-6 sm:p-8 rounded-lg shadow-md">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-center text-[#66B539]">
            Contact Us
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <motion.div className="col-span-1 text-left" data-aos="fade-up">
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">
                Address
              </h3>
              <p className="mb-4">Makati, Philippines</p>
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Email</h3>
              <MailTo email={"ask@babylon2k.org"} label={"BETH Inquiry"} />
            </motion.div>
            <motion.div
              className="col-span-1 text-center sm:text-left"
              data-aos="fade-up"
            >
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">
                Follow Us
              </h3>
              <ul className="flex justify-center space-x-4 sm:justify-start">
                <li>
                  <a
                    href="https://www.facebook.com/babyloneth"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} size="lg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/uhy_mac"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} size="lg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/babylon2k"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
                  </a>
                </li>
              </ul>
            </motion.div>
            <motion.div className="col-span-2 h-full" data-aos="fade-up">
              <iframe
                className="w-full h-72 sm:h-96 rounded-lg shadow-lg"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15447.45427807874!2d121.013302!3d14.5497934!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c91377a5394b%3A0x46b25c2900f79882!2sBabylon!5e0!3m2!1sen!2sph!4v1718680761392!5m2!1sen!2sph"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </motion.div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
