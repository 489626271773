import React, { useState, useEffect } from "react";
import SearchFilter from "./components/SearchFilter";
import AllAccountants from "./components/AllAccountants";
import api from "../../utils/api";
import Pagination from "./components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import FeaturedAccountants from "./components/FeaturedAccountants";
import { FiArrowUpLeft } from "react-icons/fi";
import { scrollToTop } from "../../utils/navigation";

let cachedAccountants = null;

export default function FindAccountant() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");

  const [accountants, setAccountants] = useState(cachedAccountants);
  const [loading, setLoading] = useState(!cachedAccountants);
  const [filteredAccountants, setFilteredAccountants] = useState([]);
  const [optionQualif, setOptionQualif] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [loadingId, setLoadingId] = useState(null);

  const updateItemsPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1280) {
      setItemsPerPage(24);
    } else if (screenWidth >= 1024) {
      setItemsPerPage(18);
    } else if (screenWidth >= 768) {
      setItemsPerPage(14);
    } else {
      setItemsPerPage(12);
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  useEffect(() => {
    if (!cachedAccountants) {
      const fetchAcc = async () => {
        try {
          setLoading(true);

          const response = await api.get("/accountant/all");
          cachedAccountants = response.data;
          setAccountants(response.data);
        } catch (error) {
          console.error("Error fetching user details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchAcc();
    } else {
      setAccountants(cachedAccountants);
    }
  }, []);
  useEffect(() => {
    let filtered = accountants;

    if (filter) {
      filtered = filtered.filter(
        (accountant) => accountant.qualifications === filter
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(
        (accountant) =>
          accountant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          accountant.qualifications
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }

    if (sort === "desc") {
      filtered = filtered.sort((a, b) => b.level - a.level);
    } else if (sort === "asc") {
      filtered = filtered.sort((a, b) => a.level - b.level);
    }

    setFilteredAccountants(filtered);
    setCurrentPage(1);
  }, [searchTerm, filter, sort, accountants]);

  useEffect(() => {
    const uniqueQualifications = Array.from(
      new Set(accountants?.map((accountant) => accountant.qualifications))
    );
    setOptionQualif(uniqueQualifications);
  }, [accountants]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAccountants = filteredAccountants?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredAccountants?.length / itemsPerPage);

  const handleSelectAccountant = (id, image) => {
    setLoadingId(id);
    navigate(`/accountant/profile/${id}`, { state: { image } });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="self-start pt-2"></div>
      <SearchFilter
        optionQualif={optionQualif}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        loading={loading}
      />
      {searchTerm || filter ? (
        <></>
      ) : (
        <FeaturedAccountants
          accountants={accountants}
          loading={loading}
          handleSelectAccountant={handleSelectAccountant}
          loadingId={loadingId}
        />
      )}

      {loading ? (
        <div
          className={`transition-all duration-300 ${
            loading ? "blur-sm opacity-50" : ""
          }`}
        >
          {/* <AllAccountants
            currentAccountants={currentAccountants}
            handleSelectAccountant={handleSelectAccountant}
            loadingId={loadingId}
          />
          <Pagination
            currentPage={currentPage}
            loading={loading}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          /> */}
        </div>
      ) : (
        <>
          <AllAccountants
            currentAccountants={currentAccountants}
            handleSelectAccountant={handleSelectAccountant}
            loadingId={loadingId}
          />
          <Pagination
            currentPage={currentPage}
            currentAccountants={currentAccountants}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <aside className="fixed left-6 bottom-6">
        <Link
          to="/"
          onClick={scrollToTop}
          className=" bg-white rounded-lg shadow-lg p-4 flex items-center space-x-2 hover:bg-gray-100 transition duration-300"
        >
          <FiArrowUpLeft className="text-gray-600" />
          <span className="text-gray-600">Back to Home</span>
        </Link>
      </aside>
    </div>
  );
}
