import React from "react";
import {
  FiHelpCircle,
  FiCheckCircle,
  FiBookOpen,
  FiPlay,
  FiArrowLeft,
} from "react-icons/fi";
import { FaRocket } from "react-icons/fa";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../utils/navigation";

export default function InstantResearch() {
  const navigate = useNavigate();

  const handleCall = () => {
    scrollToTop();
    navigate("/signup");
  };
  return (
    <motion.div
      className="bg-gradient-to-br from-green-50 via-green-100 to-green-50 min-h-screen flex flex-col items-center text-gray-800 font-sans"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <motion.header
        className="w-full py-8 px-8 bg-green-600 text-white text-center shadow-lg"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.7 }}
      >
        <h1 className="text-4xl font-extrabold tracking-wide">
          Instant Research
        </h1>
        <p className="mt-3 text-lg font-light max-w-2xl mx-auto">
          Get quick insights into tax laws, deadlines, penalties, and business
          regulations.
        </p>
      </motion.header>
      <main className="max-w-5xl w-full flex flex-col items-center px-6 mt-12 space-y-16">
        <motion.section
          className="text-center space-y-4 flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h2 className="text-3xl font-semibold text-gray-700 flex items-center justify-center">
            Real-time Information{" "}
            <FiHelpCircle className="ml-2 text-green-500" />
          </h2>
          <p className="text-gray-600 leading-relaxed max-w-2xl mx-auto">
            Ask B.E.T.H. anything about tax laws, deadlines, penalties, or other
            business-related queries. Receive accurate, up-to-date information
            instantly, complete with citations and links.
          </p>
          <motion.button
            className="mt-6 px-8 py-3 bg-green-600 text-white rounded-full font-semibold hover:bg-green-700 transition duration-300 shadow-lg transform hover:scale-105 flex items-center justify-center"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleCall()}
          >
            Start Your Free Journey <FaRocket className="ml-3" />
          </motion.button>
        </motion.section>

        <section className="w-full text-center px-4 space-y-12">
          <h3 className="text-2xl font-semibold text-gray-700">How It Works</h3>
          <div className="flex flex-col md:flex-row justify-center md:space-x-8 space-y-8 md:space-y-0">
            {[
              {
                icon: <FiHelpCircle />,
                title: "Ask",
                text: "Type your question about business regulations, tax details, and more.",
              },
              {
                icon: <FiCheckCircle />,
                title: "Receive",
                text: "Get responses in seconds, backed by citations and updated information.",
              },
              {
                icon: <FiBookOpen />,
                title: "Learn",
                text: "Discover insights that simplify complex topics and guide your business decisions.",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="flex-1 bg-white rounded-lg shadow-lg p-6 transform transition-transform hover:scale-105"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h4 className="text-lg font-semibold text-green-600 flex items-center justify-center space-x-2">
                  {item.icon}
                  <span>{item.title}</span>
                </h4>

                <p className="text-gray-600 mt-2">{item.text}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <motion.section
          className="w-full text-center space-y-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <h3 className="text-2xl font-semibold text-gray-700 flex items-center justify-center">
            Instructional Video <FiPlay className="ml-2 text-indigo-500" />
          </h3>
          <div className="relative w-full h-full bg-gray-100 rounded-lg shadow-lg flex items-center justify-center overflow-hidden">
            <video
              src="https://bethaibucket.s3.us-east-1.amazonaws.com/videos/INSTANT%20RESEARCH.mp4"
              controls
              className="w-full h-full object-fill rounded-lg"
            ></video>
          </div>
        </motion.section>
      </main>

      <aside className="fixed left-6 bottom-6">
        <Link
          to="/features"
          onClick={scrollToTop}
          className=" bg-white rounded-lg shadow-lg p-4 flex items-center space-x-2 hover:bg-gray-100 transition duration-300"
        >
          <FiArrowLeft className="text-gray-600" />
          <span className="text-gray-600">Back to Features</span>
        </Link>
      </aside>

      <motion.footer
        className="w-full py-8 bg-gradient-to-br from-green-50 via-green-100 to-green-50 text-gray-600 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.7 }}
      >
        <p className="text-sm">
          © {new Date().getFullYear()} Babylon by I-Lead. All rights reserved.
        </p>
      </motion.footer>
    </motion.div>
  );
}
