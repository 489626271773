import Marquee from "react-marquee-slider";
import { v4 as uuidv4 } from "uuid";

export default function Scroll() {
  const items = [
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/bir1.png",
      alt: "bir",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/SEC.png",
      alt: "sec",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/PEZA.png",
      alt: "peza",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/NEA.png",
      alt: "nea",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/CDA.png",
      alt: "cda",
    },
  ];

  return (
    <div className="w-full sm:w-1/2 overflow-hidden mx-3">
      <Marquee velocity={40} minScale={1} resetAfterTries={200}>
        {items.map((item) => (
          <div key={item.id} className="mx-4 flex justify-center items-center">
            <div className="relative w-16 h-16 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 flex justify-center items-center">
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-full object-contain rounded-lg"
              />
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
}
