import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Testimonials() {
  return (
    <>
      <section className="relative z-0 mt-16 py-12 px-6 rounded-lg shadow-lg ">
        <div className="text-center px-4 md:px-12">
          <h2 className="text-4xl font-extrabold text-center text-black mb-8">
            Testimonials
          </h2>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            keyboard={{ enabled: true }}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            // navigation={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Keyboard, Pagination, Navigation, Autoplay]}
          >
            <SwiperSlide className="my-8">
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gray-200">
                  <i className="bi bi-person text-4xl text-gray-400"></i>
                </div>
                <p className="text-xl mb-4">
                  "I appreciate BETH AI for its efficiency in cross-checking
                  information. It's been very useful in my audit processes.
                  Adding a history feature and improving system stability would
                  make it even better, but overall, it's a helpful tool"
                </p>
                <p className="font-semibold">H. Neri, Auditor</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-8">
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gray-200">
                  <i className="bi bi-person text-4xl text-gray-400"></i>
                </div>
                <p className="text-xl mb-4">
                  "I am very satisfied with BETH AI. Cross-checking information
                  is efficient and reliable. It has greatly helped in my audit
                  processes."
                </p>
                <p className="font-semibold">J. Mendoza, Auditor</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-8">
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gray-200">
                  <i className="bi bi-person text-4xl text-gray-400"></i>
                </div>
                <p className="text-xl mb-4">
                  "I am very satisfied with the BETH AI Chatbot Tool. Asking
                  questions about business-related topics has been a smooth and
                  helpful experience."
                </p>
                <p className="font-semibold">F. Umban, Auditor</p>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide className="my-8">
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-gray-200">
                  <i className="bi bi-person text-4xl text-gray-400"></i>
                </div>
                <p className="text-xl mb-4">"All my work done in just 30 minutes!"</p>
                <p className="font-semibold">BINI Maloi</p>
              </div>
            </SwiperSlide> */}
          </Swiper>
          <div className="swiper-pagination absolute bottom-4 left-1/2 transform -translate-x-1/2"></div>
        </div>
      </section>
    </>
  );
}
