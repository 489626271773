import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const recorderToolkit = ({ isRecording }) => {
  if (!isRecording) return null;

  return (
    <div className="flex items-center justify-center p-2 mb-2 bg-yellow-50 border border-yellow-300 rounded-lg shadow-sm">
      <div className="mr-3 text-yellow-500">
        <FontAwesomeIcon icon={faMicrophone} className="h-6 w-6" />
      </div>
      <p className="text-yellow-700 text-xs font-medium">
        Please speak clearly. External factors such as background noise may
        affect the quality of your recording.
      </p>
    </div>
  );
};
