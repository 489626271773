import React from "react";

const PredefinedQuestions = ({
  predefinedQuestions,
  handlePredefinedQuestionClick,
}) => {
  return (
    <div className="flex flex-col justify-center my-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {predefinedQuestions.map((predefined, index) => (
          <button
            key={index}
            className="p-3 bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 hover:dark:bg-gray-500 hover:bg-gray-300 rounded shadow transition duration-300 text-xs"
            onClick={() => handlePredefinedQuestionClick(predefined.question)}
            aria-label={`Select question: ${predefined.question}`}
          >
            {predefined.question}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PredefinedQuestions;
