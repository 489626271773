import PhoneInput from "react-phone-input-2";

export default function BillingForm({ phoneNumber, handlePhoneChange }) {
  return (
    <>
      <label
        htmlFor="email"
        className="block text-gray-700 mt-4 dark:text-gray-200"
      >
        Email
      </label>
      <input
        type="email"
        id="email"
        placeholder="Enter email"
        className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:focus:ring-green-500"
        required
      />

      <label
        htmlFor="firstName"
        className="block text-gray-700 mt-4 dark:text-gray-200"
      >
        First Name
      </label>
      <input
        type="text"
        id="firstName"
        placeholder="Enter first name"
        className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:focus:ring-green-500"
        required
      />

      <label
        htmlFor="lastName"
        className="block text-gray-700 mt-4 dark:text-gray-200"
      >
        Last Name
      </label>
      <input
        type="text"
        id="lastName"
        placeholder="Enter last name"
        className="w-full p-3 border-2 rounded-lg my-2 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:focus:ring-green-500"
        required
      />

      <label
        htmlFor="phoneNumber"
        className="block text-gray-700 mt-4 dark:text-gray-200"
      >
        Phone
      </label>
      <div className="relative w-full ">
        <PhoneInput
          inputProps={{
            id: "phoneNumber",
            name: "phoneNumber",
            placeholder: "Enter phone number",
          }}
          country={"ph"}
          required
          value={phoneNumber}
          onChange={handlePhoneChange}
          countryCodeEditable={false}
          containerClass="flex items-center w-full rounded-lg my-2 focus-within:ring-2 focus-within:ring-green-500"
          inputClass="flex-1 p-3 border-none outline-none rounded-lg"
          buttonClass="p-2 hover:bg-gray-100 rounded-lg focus:outline-none"
          dropdownClass="absolute mt-1 w-full border rounded-lg shadow-lg text-white dark:text-gray-800"
          containerStyle={{
            backgroundColor: "var(--bg-container)",
            borderColor: "var(--border-container)",
          }}
          inputStyle={{
            color: "var(--text-input)",
            backgroundColor: "var(--bg-input)",
          }}
          buttonStyle={{
            color: "var(--text-button)",
            backgroundColor: "var(--bg-button)",
          }}
          // dropdownStyle={{
          //   color: "var(--text-dropdown)",
          //   backgroundColor: "var(--bg-dropdown)",
          // }}
        />
      </div>
    </>
  );
}
