import { useState } from "react";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";

export const useRegistration = () => {
  const [industry, setIndustry] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherOptionText, setOtherOptionText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [newsletter, setNewsLetter] = useState(false);

  const navigate = useNavigate();

  const countryOptions = countryList().getData();

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(selectedOption === value ? "" : value);
  };
  const handleClearSelection = () => {
    setSelectedOption("");
    setOtherOptionText("");
  };
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const checkEmail = async (email) => {
    try {
      const response = await api.get(`/email/check`, { params: { email } });
      return response.data.is_registered;
    } catch (error) {
      console.error("There was an error checking the email!", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isEmailRegistered = await checkEmail(email);
    if (isEmailRegistered) {
      toast.error("Email is already registered");
      setLoading(false);
      return;
    }

    if (!validatePassword()) {
      setLoading(false);
      return;
    }

    try {
      await api.post(
        `/register/`,
        {
          user: {
            email,
            password,
            level: "free",
          },
          details: {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            job_title: jobTitle,
          },
          newsletter: newsletter,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success("Registration successful!");
      setLoading(false);
      setTimeout(
        () => navigate("/email/otp", { state: { userEmail: email } }),
        1500
      );
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  const validatePassword = () => {
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return false;
    }
    if (password.toLowerCase() === password) {
      toast.error("Password must contain at least one uppercase letter");
      return false;
    }
    if (password.toUpperCase() === password) {
      toast.error("Password must contain at least one lowercase letter");
      return false;
    }
    if (!/\d/.test(password)) {
      toast.error("Password must contain at least one number");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }
    return true;
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 422) {
      toast.error("Validation Error: Please check your input data");
    } else {
      toast.error(error.response?.data?.detail || "Registration failed");
    }
  };
  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    setIndustry(selectedIndustry);

    // Clear otherIndustry if user selects an option other than "Other"
    if (selectedIndustry !== "other") {
      setOtherIndustry("");
    }
  };
  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    console.log(selectedCountry);
  };

  return {
    email,
    industry,
    otherIndustry,
    password,
    phoneNumber,
    confirmPassword,
    firstName,
    lastName,
    companyName,
    jobTitle,
    loading,
    otherOptionText,
    countryOptions,
    country,
    selectedOption,
    newsletter,

    setOtherOptionText,
    handleClearSelection,
    handleCountryChange,
    handlePhoneChange,
    setEmail,
    setPassword,
    setConfirmPassword,
    setFirstName,
    setLastName,
    setCompanyName,
    setJobTitle,
    handleIndustryChange,
    setOtherIndustry,
    handleSubmit,
    handleOptionChange,
    setNewsLetter,
  };
};
