import Marquee from "react-marquee-slider";
import { v4 as uuidv4 } from "uuid";

export default function ScrollPartners() {
  const items = [
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/UHY%20-%20mlaguirreco.com.jpg",
      alt: "uhy",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Screenshot%202024-12-13%20150812.png",
      alt: "dgp",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/ab%26c%20-%20mtpfgroup.com.png",
      alt: "abc",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/chiefs-logo.jpg",
      alt: "chiefs",
    },
    {
      id: uuidv4(),
      src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/macdbs-logo.png",
      alt: "macdbs",
    },
  ];

  return (
    <div className="w-full overflow-hidden mx-3">
      <Marquee
        velocity={40}
        minScale={1}
        resetAfterTries={200}
        className="w-full mx-auto"
      >
        {items.map((item) => (
          <div key={item.id} className="mx-4 flex justify-center items-center">
            <div className="relative w-16 h-16 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 flex justify-center items-center">
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-full object-contain rounded-lg"
              />
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
}
