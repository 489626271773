import React from "react";
import Navbar from "../../components/NavBar/NavBar";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMsal } from "@azure/msal-react";
import UserForm from "./UserForm";

export default function SignUp() {
  const { instance } = useMsal();

  const handleMicrosoftLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["openid", "profile", "User.Read"],
      });

      const user = loginResponse.account;
      toast.success("Microsoft sign in successful!");
    } catch (error) {
      console.error(error);
      toast.error("Microsoft sign in failed. Please try again.");
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen flex flex-col">
      <ToastContainer />
      <Navbar />
      <main className="flex-grow container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-4xl text-green-500 font-bold mb-6 text-center">
            Sign Up
          </h2>
          <UserForm handleMicrosoftLogin={handleMicrosoftLogin} />
        </div>
      </main>
    </div>
  );
}
