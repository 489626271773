import { useRef, useState } from "react";
import api from "../../../utils/api";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCachedHistory,
  updateCachedThreadLastUsed,
  regroupThreadsByDate,
  setCachedHistory,
} from "../../../components/SideBar/cache/caching";

export const fetchChatHistory = async (token, setThreadId, setRefresh) => {
  try {
    const response = await api.get(`/chat/history/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const history = response.data;
    if (history?.length > 0) {
      setThreadId(history[0].thread_id);
      sessionStorage.setItem("threadId", history[0].thread_id);
    } else {
      const newThread = await api.get(`/chat/new/thread`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setThreadId(newThread.data.thread_id);
      sessionStorage.setItem("threadId", newThread.data.thread_id.toString());
      setRefresh((prev) => !prev);
    }
  } catch (error) {
    console.error("Error fetching chat history:", error);
  }
};

export const useSendMessage = (
  token,
  user_level,
  threadId,
  setMessages,
  setIsLoading,
  setHasError,
  setWait,
  setDidSearch,
  setLinkSearch,
  setData,
  setNewMessage,
  runId,
  setRunId,
  messageId,
  setMessageId,
  setEventError,
  setRefresh,
  eventSourceRef
) => {
  const handleSendMessage = async (
    message,
    file,
    sender = "user",
    tokenCount
  ) => {
    const trimmedMessage = message.trim();
    setNewMessage("");
    setEventError(false);
    const selectedFile = file?.current?.files[0];

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: sender,
        text_value: trimmedMessage,
        file: file ? file.name : null,
      },
      {
        role: "assistant",
        text_value: "Analyzing your message...",
        isAnalyzing: true,
      },
    ]);

    setIsLoading(true);

    setLinkSearch(false);

    const formData = new FormData();
    let finalMessage = trimmedMessage;

    // Check if a file exists, and if so, append the threadId to the message
    if (file) {
      finalMessage = `${trimmedMessage} from the thread id of ${threadId}`;
    }

    formData.append("thread_id", threadId);
    formData.append("message", finalMessage);

    if (file) {
      formData.append("file", file);
    }

    try {
      const cached = getCachedHistory();
      if (cached) {
        const allItems = Object.values(cached).flat();
        const updatedItems = allItems.map((item) => {
          if (item.thread_id === parseInt(threadId)) {
            return {
              ...item,
              last_used: new Date().toISOString(),
            };
          }
          return item;
        });
        const regroupedData = regroupThreadsByDate(updatedItems);
        setCachedHistory(regroupedData);
        setRefresh((prev) => !prev); // Trigger refresh only if cache is updated
      }

      await api.post(`/chat/response/assistant`, formData);
      eventSourceRef.current = new EventSource(
        `https://api.babylon2k.org/chat/stream/v2?thread_id=${threadId}&token=${token}`
      );

      if (user_level === "free") {
        eventSourceRef.current.onopen = async () => {
          await api.post(
            `/chat/update/token?chat_token=${tokenCount}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        };
      }
      let accumulatedText = "";
      let timeoutId;

      eventSourceRef.current.onmessage = (event) => {
        const eventData = JSON.parse(event.data);

        if (["text_created", "text_delta"].includes(eventData.event)) {
          accumulatedText += eventData.data;

          setDidSearch(false);
          setIsLoading(false);
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setWait(false);
          }, 300);
        } else if (eventData.event === "run_id") {
          setRunId(eventData.data);
          setWait(true);
        } else if (eventData.event === "message_id") {
          setMessageId(eventData.data);
        } else if (eventData.event === "message_after_search") {
          accumulatedText += eventData.data;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setDidSearch(false);
            setWait(false);
            setIsLoading(false);
          }, 300);
        } else if (eventData.event === "tool_call_created") {
          setDidSearch(true);
          setIsLoading(false);
        } else if (eventData.event === "tool_call_done") {
          setDidSearch(false);
          setLinkSearch(true);
          setData(eventData.data);
        } else if (eventData.event === "stream_completed") {
          eventSourceRef.current.close();
          handleRename(token, threadId, trimmedMessage, setRefresh);
        }
        const cleanedText = accumulatedText.replace(/【[^】]*】/g, "").trim();

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            text_value: cleanedText,
            isAnalyzing: false,
          };
          localStorage.setItem(
            `messages_${threadId}`,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      };

      eventSourceRef.current.onerror = (error) => {
        eventSourceRef.current.close();
        setIsLoading(false);
        setEventError(true);
        setWait(false);
      };
    } catch (error) {
      setIsLoading(false);
      setEventError(true);
      setWait(false);
      setHasError(true);
    }
  };

  return { handleSendMessage };
};

export const handleGenerateName = async (message, token) => {
  try {
    const response = await api.post(
      `/chat/generate/name?query=${encodeURIComponent(message)}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.short_name;
  } catch (error) {
    console.error("Failed to rename thread:", error);
  }
};
export const handleRename = async (token, threadId, message, setRefresh) => {
  try {
    const sidebarHistory = JSON.parse(
      localStorage.getItem("sidebar_history") || "{}"
    );

    const allThreads = Object.values(sidebarHistory.data || {}).flat();
    const currentThread = allThreads.find(
      (thread) => thread.thread_id === threadId
    );

    // Ensure the thread has not already been renamed
    if (currentThread && currentThread.name !== "New Thread") {
      return;
    }

    // Generate a new name for the thread
    const generatedName = await handleGenerateName(message, token);
    const newName = generatedName || "New Thread";

    // Check if the generated name is the same as the existing name
    if (currentThread && currentThread.name === newName) {
      return; // Avoid redundant renaming
    }

    // Rename the thread via API
    await api.post(
      `/chat/thread/rename?thread_id=${threadId}&new_name=${encodeURIComponent(
        newName
      )}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Update local storage immediately
    if (sidebarHistory.data) {
      for (const section in sidebarHistory.data) {
        sidebarHistory.data[section] = sidebarHistory.data[section].map(
          (thread) =>
            thread.thread_id === threadId
              ? { ...thread, name: newName } // Update thread name
              : thread
        );
      }
      localStorage.setItem("sidebar_history", JSON.stringify(sidebarHistory));
    }

    // Trigger a refresh to update the UI
    setRefresh((prev) => !prev);
  } catch (error) {
    console.error("Failed to rename thread:", error);
  }
};

export const errorTool = ({
  hasError,
  setHasError,
  token,
  messageId,
  threadId,
  messages,
  setMessages,
  handleSendMessage,
  setIsLoading,
  setWait,
  setLinkSearch,
  setDidSearch,
  setRunId,
  setMessageId,
  setData,
  eventSourceRef,
}) => {
  const handleRegenerate = async () => {
    try {
      await api.delete(`/chat/stream/delete/${threadId}/message/${messageId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const cached = getCachedHistory();
      if (cached) {
        const allItems = Object.values(cached).flat();
        const updatedItems = allItems.map((item) => {
          if (item.thread_id === parseInt(threadId)) {
            return {
              ...item,
              last_used: new Date().toISOString(),
            };
          }
          return item;
        });
        const regroupedData = regroupThreadsByDate(updatedItems);
        setCachedHistory(regroupedData);
      }

      setMessages((prevMessages) => prevMessages.slice(0, -1));

      const lastUserMessage = messages
        .slice()
        .reverse()
        .find((msg) => msg.role === "user");

      if (lastUserMessage) {
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          lastUserMessage,
          { role: "assistant", text_value: "" },
        ]);

        if (eventSourceRef.current) eventSourceRef.current.close();

        // eventSourceRef.current = new EventSource(
        //   `https://uatf.macdbs.com/chat/stream/v2?thread_id=${threadId}&token=${token}`
        // );
        eventSourceRef.current = new EventSource(
          `https://api.babylon2k.org/chat/stream/v2?thread_id=${threadId}&token=${token}`
        );
        setIsLoading(true);

        setLinkSearch(false);
        setHasError(false);

        let accumulatedText = "";
        let timeoutId;

        eventSourceRef.current.onmessage = (event) => {
          const eventData = JSON.parse(event.data);

          if (["text_created", "text_delta"].includes(eventData.event)) {
            accumulatedText += eventData.data;

            setDidSearch(false);
            setIsLoading(false);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWait(false), 300);
          } else if (eventData.event === "run_id") {
            setRunId(eventData.data);
            setWait(true);
          } else if (eventData.event === "message_id") {
            setMessageId(eventData.data);
          } else if (eventData.event === "message_after_search") {
            accumulatedText += eventData.data;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              setDidSearch(false);
              setWait(false);
              setIsLoading(false);
            }, 300);
          } else if (eventData.event === "tool_call_created") {
            setDidSearch(true);
            setIsLoading(false);
          } else if (eventData.event === "tool_call_done") {
            setDidSearch(false);
            setLinkSearch(true);
            setData(eventData.data);
          }

          const cleanedText = accumulatedText.replace(/【[^】]*】/g, "").trim();
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1] = {
              ...updatedMessages[updatedMessages.length - 1],
              role: "assistant",
              text_value: cleanedText,
            };
            localStorage.setItem(
              `messages_${threadId}`,
              JSON.stringify(updatedMessages)
            );

            return updatedMessages;
          });
        };

        eventSourceRef.current.onerror = () => {
          eventSourceRef.current.close();
          setIsLoading(false);
          setHasError(true);
        };
      }
    } catch (error) {
      console.error("Error during regeneration:", error);
      setIsLoading(false);
      setHasError(true);
    }
  };

  return (
    hasError && (
      <div className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-red-600 text-white p-4 rounded-lg shadow-lg flex items-center space-x-3 z-50">
        <FontAwesomeIcon icon={faTimesCircle} className="text-white" />
        <span>Error occurred!</span>
        <button
          onClick={handleRegenerate}
          className="bg-white text-red-600 px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-200"
        >
          Retry
        </button>
      </div>
    )
  );
};

export function preprocessLaTeX(content) {
  // Step 1: Protect code blocks
  const codeBlocks = [];
  content = content.replace(/(```[\s\S]*?```|`[^`\n]+`)/g, (match) => {
    codeBlocks.push(match);
    return `<<CODE_BLOCK_${codeBlocks.length - 1}>>`;
  });

  // Step 2: Protect existing LaTeX expressions
  const latexExpressions = [];
  content = content.replace(
    /(\$\$[\s\S]*?\$\$|\\\[[\s\S]*?\\\]|\\\(.*?\\\))/g,
    (match) => {
      latexExpressions.push(match);
      return `<<LATEX_${latexExpressions.length - 1}>>`;
    }
  );

  // Step 3: Escape dollar signs that are likely currency indicators
  content = content.replace(/\$(?=\d)/g, "\\$");

  // Step 4: Restore LaTeX expressions
  content = content.replace(
    /<<LATEX_(\d+)>>/g,
    (_, index) => latexExpressions[parseInt(index)]
  );

  // Step 5: Restore code blocks
  content = content.replace(
    /<<CODE_BLOCK_(\d+)>>/g,
    (_, index) => codeBlocks[parseInt(index)]
  );

  // Step 6: Apply additional escaping functions
  content = escapeBrackets(content);
  content = escapeMhchem(content);

  return content;
}

export function escapeBrackets(text) {
  const pattern =
    /(```[\S\s]*?```|`.*?`)|\\\[([\S\s]*?[^\\])\\]|\\\((.*?)\\\)/g;
  return text.replace(
    pattern,
    (match, codeBlock, squareBracket, roundBracket) => {
      if (codeBlock != null) {
        return codeBlock;
      } else if (squareBracket != null) {
        return `$$${squareBracket}$$`;
      } else if (roundBracket != null) {
        return `$${roundBracket}$`;
      }
      return match;
    }
  );
}

export function escapeMhchem(text) {
  return text.replaceAll("$\\ce{", "$\\\\ce{").replaceAll("$\\pu{", "$\\\\pu{");
}