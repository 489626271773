import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ReactComponent as Loader } from "../../../assets/loader.svg";

const ModalAccountantContact = ({
  setIsModalOpen,
  accountant,
  handleChange,
  handleSubmit,
  formData,
  loadingSubmit,
  setLoadingSubmit,
}) => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
          <h2 className="text-xl font-bold text-gray-800 mb-4">
            Contact Accountant
          </h2>
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Name
              </label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-green-500 focus:border-green-500 text-gray-700"
                placeholder="Input Name"
                required
              />
            </div>

            {/* Email Field */}
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-green-500 focus:border-green-500 text-gray-700"
                placeholder="Input Email"
                required
              />
            </div>

            {/* Message Field */}
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-green-500 focus:border-green-500 text-gray-700"
                placeholder="Input Message"
                rows={4}
                required
              />
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="py-2 px-4 text-gray-600 bg-gray-200 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-300 focus:ring-2 focus:ring-gray-200 focus:outline-none mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-2 px-4 bg-green-600 text-white rounded-lg shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:outline-none"
                disabled={loadingSubmit}
              >
                {loadingSubmit ? (
                  <Loader id="spinner" className="h-6 w-6 mx-auto" />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalAccountantContact;
