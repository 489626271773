import React from "react";
import { FiArrowUpLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utils/navigation";

export default function Terms() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    {
      navigate(-1) || navigate("/");
    }
    setTimeout(() => {
      scrollToTop();
    }, 50);
  };
  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-10 px-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl p-8 relative">
        <button
          onClick={handleBackClick}
          className="absolute top-4 right-4 mt-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <div>
          <img
            src="/babylon1.png"
            alt="Logo"
            className="h-16 mb-4 mr-4 sm:mr-6 lg:mr-8"
          />
          <p className="mb-4 text-2xl">
            <strong>Terms of Use</strong>
          </p>
          <div>
            <p className="mb-4">
              Welcome to <strong>ai.babylon2k.org</strong>! By accessing and
              using the <strong>B.E.T.H. AI Chatbot </strong>
              on this website, you agree to comply with and be bound by the
              following <strong>Terms of Use</strong>. Please read these terms
              carefully before using the platform. If you do not agree with
              these terms, you should discontinue use of the website
              immediately.
            </p>

            <ol className="mb-4 list-decimal pl-5">
              <li>
                <strong>Acceptance of Terms</strong>
                <p className="mb-5">
                  By accessing or using <strong>ai.babylon2k.org</strong>, you
                  acknowledge that you have read, understood, and agree to be
                  bound by these <strong>Terms of Use</strong> and our{" "}
                  <strong>Privacy Policy</strong>. These terms apply to all
                  visitors, users, and others who access or use the website.:
                </p>
              </li>

              <li>
                <strong>Use of the Website</strong>
                <p>
                  The <strong>B.E.T.H. AI Chatbot</strong> is intended to
                  provide information and solutions related to tax, accounting,
                  audit, and licensing for users in the Philippines. By using
                  this platform, you agree to comply with all applicable laws,
                  regulations, and these Terms of Use.
                </p>
                <p className="font-bold my-2">Prohibited Activities</p>
                <p>
                  You are strictly prohibited from using the website or chatbot
                  for the following:
                </p>
                <ul className="list-disc pl-5 mb-5">
                  <li>Engaging in any unlawful activities or purposes.</li>
                  <li>
                    Uploading, sharing, or distributing harmful, offensive,
                    defamatory, or inappropriate content.
                  </li>
                  <li>
                    Interfering with the security, functionality, or performance
                    of the platform.
                  </li>
                  <li>
                    Attempting to access, tamper with, or compromise our backend
                    systems or any unauthorized areas of the website.
                  </li>
                </ul>
              </li>

              <li>
                <strong>User Accounts</strong>
                <p className="mb-5">
                  To access certain features of the chatbot, you may be required
                  to create an account. You are responsible for maintaining the
                  confidentiality of your account credentials and for any
                  activities that occur under your account. You agree to notify
                  us immediately of any unauthorized use of your account.
                </p>
              </li>

              <li>
                <strong>Intellectual Property</strong>
                <p>
                  All content, features, and functionality of{" "}
                  <strong>ai.babylon2k.org</strong>, including but not limited
                  to text, graphics, logos, images, and software, are the
                  intellectual property of <strong>B.E.T.H. AI Chatbot</strong>{" "}
                  and its affiliates or licensors. You are granted a limited,
                  non-exclusive license to access and use the platform solely
                  for your personal or business use in accordance with these
                  terms.
                </p>
                <p className="font-bold my-2">Restrictions</p>
                <p>You agree not to: </p>
                <ul className="list-disc pl-5 mb-5">
                  <li>
                    Reproduce, modify, or distribute any content from this
                    website without prior written consent.
                  </li>
                  <li>
                    Use any automated means (including bots or scrapers) to
                    collect data from the platform.
                  </li>
                  <li>
                    Reverse-engineer, decompile, or attempt to obtain the source
                    code of the chatbot.
                  </li>
                </ul>
              </li>

              <li>
                <strong>Disclaimer of Warranties</strong>
                <p className="mb-5">
                  The <strong>B.E.T.H. AI Chatbot</strong> and all related
                  services are provided on an "as is" and "as available" basis.
                  We make no warranties, express or implied, regarding the
                  accuracy, reliability, or availability of the information
                  provided by the chatbot. <br />
                  <strong>B.E.T.H. AI Chatbot</strong> disclaims any warranties
                  of merchantability, fitness for a particular purpose, or
                  non-infringement. We do not guarantee that the platform will
                  be error-free, uninterrupted, or free from viruses or harmful
                  components.
                </p>
              </li>

              <li>
                <strong>Limitation of Liability</strong>
                <p className="mb-5">
                  To the fullest extent permitted by law,{" "}
                  <strong>B.E.T.H. AI Chatbot</strong>, its owners, affiliates,
                  and partners will not be liable for any direct, indirect,
                  incidental, special, or consequential damages resulting from
                  your use or inability to use the platform. This includes, but
                  is not limited to, damages for loss of profits, business
                  interruptions, or other intangible losses.
                </p>
              </li>

              <li>
                <strong>User Data and Privacy</strong>
                <p className="mb-5">
                  Any personal information you provide to us through your
                  interactions with the chatbot is subject to our{" "}
                  <strong>Privacy Policy</strong>. We take security seriously
                  and strive to protect your data through appropriate encryption
                  and storage methods. <br />
                  While we collect certain data for generating reports and
                  identifying hot topics (as described in our{" "}
                  <strong>Privacy Policy</strong>), we do not access or use
                  sensitive files uploaded by users beyond their intended
                  purposes in providing chatbot responses.
                </p>
              </li>

              <li>
                <strong>Modifications to the Terms</strong>
                <p className="mb-5">
                  We reserve the right to modify or update these Terms of Use at
                  any time, and any changes will be effective upon posting to
                  this page. It is your responsibility to review the terms
                  periodically. Continued use of the website after any such
                  changes constitutes your acceptance of the new terms.
                </p>
              </li>
              <li>
                <strong>Third-Party Links</strong>
                <p className="mb-5">
                  Our platform may contain links to third-party websites or
                  services that are not owned or controlled by{" "}
                  <strong>B.E.T.H. AI Chatbot</strong>. We have no control over,
                  and assume no responsibility for, the content, privacy
                  policies, or practices of any third-party websites or
                  services.
                </p>
              </li>

              <li>
                <strong>Terminations of Use</strong>
                <p className="mb-5">
                  We reserve the right to suspend or terminate your access to
                  the platform at our sole discretion, without notice or
                  liability, for any reason, including violation of these{" "}
                  <strong>Terms of Use.</strong>
                </p>
              </li>

              <li>
                <strong>Governing Law</strong>
                <p className="mb-5">
                  These <strong>Terms of Use</strong> shall be governed by and
                  construed in accordance with the laws of the Republic of the
                  Philippines, without regard to its conflict of law provisions.
                </p>
              </li>

              <li>
                <strong>Contact Us</strong>
                <p>
                  If you have any questions about this Privacy Policy or how
                  your data is handled, please contact us at:
                </p>
                <p className="font-bold">B.E.T.H. AI Chatbot</p>
                <p>
                  Email: <strong>ask@babylon2k.org</strong>
                </p>
                <p>
                  Website <strong>ai.babylon2k.org</strong>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
