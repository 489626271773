import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LetUsKnowModal } from "./LetUsKnowModal";
import api from "../../utils/api";

export default function NotFound() {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    try {
      await api.post("/email/contact", formData);
      setFormData({ name: "", email: "", message: "" });
      toast.success("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send email. Please try again.");
    } finally {
      setLoadingSubmit(false);
      setModal(false);
    }
  };

  return (
    <section className="h-screen flex flex-col justify-center items-center bg-gradient-to-br from-gray-50 to-gray-200 ">
      <ToastContainer />
      <div className="text-center p-8 bg-white shadow-xl rounded-lg max-w-md mx-4 mt-4">
        <div className="mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mx-auto text-red-500"
            viewBox="0 0 64 64"
            fill="none"
          >
            <path
              d="M44 44l10 10"
              stroke="red"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />

            <circle
              cx="32"
              cy="32"
              r="12"
              stroke="red"
              strokeWidth="4"
              fill="none"
            />
          </svg>
        </div>

        {/* 404 and Main Message */}
        <h1 className="text-7xl font-extrabold text-red-500 tracking-tight">
          404
        </h1>
        <h2 className="text-2xl font-semibold mt-4 text-gray-800">
          Page Not Found
        </h2>
        <p className="mt-2 text-gray-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>

        <div className="mt-6 flex flex-col sm:flex-row justify-center items-center gap-4">
          <Link
            to="/"
            className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg shadow hover:bg-green-700 transition transform hover:scale-105"
          >
            Back to Home
          </Link>
        </div>
      </div>

      <footer className="mt-12 text-sm text-gray-500">
        <p>
          If you believe this is an error, please{" "}
          <span
            onClick={() => setModal(true)}
            className="cursor-pointer text-green-500 hover:underline text-center"
          >
            let us know
          </span>
          .
        </p>
      </footer>
      {modal && (
        <LetUsKnowModal
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loadingSubmit={loadingSubmit}
          setModal={setModal}
        />
      )}
    </section>
  );
}
