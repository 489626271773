export const CACHE_KEYS = {
    SIDEBAR_HISTORY: 'sidebar_history'
};

const CACHE_DURATION = 1000 * 60 * 30; // 30 minutes

export const getCachedHistory = () => {
    const cached = localStorage.getItem(CACHE_KEYS.SIDEBAR_HISTORY);
    if (!cached) return null;

    const { data, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp > CACHE_DURATION) {
        localStorage.removeItem(CACHE_KEYS.SIDEBAR_HISTORY);
        return null;
    }
    return data;
};

export const setCachedHistory = (historyData) => {
    const cache = {
        data: historyData,
        timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEYS.SIDEBAR_HISTORY, JSON.stringify(cache));
};

export const regroupThreadsByDate = (items) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);
    const last14Days = new Date(today);
    last14Days.setDate(today.getDate() - 14);
    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);

    const grouped = {
        today: [],
        yesterday: [],
        last7Days: [],
        last14Days: [],
        last30Days: [],
        older: []
    };

    // Ensure we're working with an array
    const allItems = Array.isArray(items) ? items : Object.values(items).flat();

    // Sort items by last_used before grouping
    const sortedItems = allItems.sort((a, b) =>
        new Date(b.last_used) - new Date(a.last_used)
    );

    sortedItems.forEach(item => {
        const lastUsed = new Date(item.last_used);
        const itemDate = new Date(lastUsed.getFullYear(), lastUsed.getMonth(), lastUsed.getDate());

        if (itemDate.getTime() === today.getTime()) {
            grouped.today.push(item);
        } else if (itemDate.getTime() === yesterday.getTime()) {
            grouped.yesterday.push(item);
        } else if (itemDate >= last7Days && itemDate < yesterday) {
            grouped.last7Days.push(item);
        } else if (itemDate >= last14Days && itemDate < last7Days) {
            grouped.last14Days.push(item);
        } else if (itemDate >= last30Days && itemDate < last14Days) {
            grouped.last30Days.push(item);
        } else {
            grouped.older.push(item);
        }
    });

    // Sort each group by last_used
    Object.keys(grouped).forEach(key => {
        grouped[key].sort((a, b) =>
            new Date(b.last_used) - new Date(a.last_used)
        );
    });

    return grouped;
};