import React from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//results of google search

const useLinkSearch = ({ data, index, message, messages }) => {
  return (
    <>
      {message.role === "assistant" && index === messages.length - 1 && (
        <div className="relative w-full max-w-full sm:max-w-[14rem] md:max-w-[20rem] lg:max-w-[24rem] overflow-hidden bg-white dark:bg-gray-800 rounded-lg mb-4">
          <div className="flex items-center space-x-3">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="text-gray-600 dark:text-gray-300"
            />
            <span className="text-lg font-semibold text-gray-800 dark:text-gray-200">
              Search Results
            </span>
          </div>

          <div className="flex max-w-[14rem] sm:max-w-full overflow-x-auto space-x-4 py-3 mb-2 scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-thumb-rounded-full">
            {data.map((item, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-40 bg-white dark:bg-gray-700 shadow-lg rounded-lg border border-gray-100 dark:border-gray-600 p-3 transform transition-transform hover:scale-105 hover:shadow-xl duration-200"
              >
                <img
                  src={item.logo}
                  alt={`${item.display_link} logo`}
                  className="w-8 h-8 object-cover rounded-full mb-3 text-center"
                  onError={(e) => {
                    e.target.src = "browser.svg";
                  }}
                />
                <div className="flex flex-col text-xs">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 dark:text-blue-400 font-semibold hover:underline leading-tight"
                  >
                    {item.title.length > 40
                      ? item.title.slice(0, 40) + "..."
                      : item.title}
                  </a>
                  <span className="text-gray-400 dark:text-gray-500 text-xs mt-1">
                    {item.display_link}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default useLinkSearch;
