import Modal from "react-modal";

export default function PlanModal({
  modalOpen,
  closeModal,
  userLevel,
  openPaymentModal,
}) {
  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Payments"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 relative max-h-full overflow-y-auto text-gray-900">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="text-center mb-6">
            <h2 className="text-2xl font-bold">Upgrade Your Plan</h2>
            <p className="text-gray-600">
              Choose the plan that suits your needs.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-stretch space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="border-2 shadow-sm rounded-lg p-4">
              <h3 className="text-xl font-bold">Free</h3>
              <p className="text-2xl font-bold">$0/month</p>
              {userLevel === "free" && (
                <div className="mt-2 bg-green-100 text-green-800 font-semibold p-2 rounded-md flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>Your Current Plan</span>
                </div>
              )}
              <ul className="mt-4 list-disc pl-4 text-left flex-grow">
                <li>Assistance with writing, problem solving, and more</li>
                <li>Limited access to GPT-4o mini</li>
                <li>
                  Limited access to advanced data analysis and web browsing
                </li>
              </ul>
            </div>

            {/* Premium Plan */}

            <div
              className={`border-2 shadow-lg rounded-lg p-4 mt-10 transition-colors duration-300 ${
                userLevel === "premium"
                  ? "border-green-500 bg-gradient-to-r from-green-50 to-white"
                  : ""
              }`}
            >
              <h3 className="text-xl font-extrabold text-gray-900">
                Premium Plan
              </h3>

              <p className="text-2xl font-bold text-green-600 ">
                $16.99/month
                {/* <span className="line-through text-gray-400 ml-2">$16.99</span> */}
              </p>

              {userLevel === "free" ? (
                <button
                  onClick={openPaymentModal}
                  className="my-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Upgrade to Premium
                </button>
              ) : (
                <div className="mt-2 bg-green-500 text-white font-semibold p-2 rounded-md flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>Your Current Plan</span>
                </div>
              )}

              <ul className="mt-2 list-disc pl-4 text-left flex-grow">
                <li>Early access to new features</li>
                <li>
                  Access to advanced data analysis, file uploads, vision, and
                  web browsing
                </li>
                <li>Comprehensive access to GPT-4</li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
