import React from "react";
import { FaTimesCircle } from "react-icons/fa";

export default function PaymentFail() {
  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-r from-red-400 to-pink-500">
      <div className="bg-white shadow-lg rounded-lg p-6 text-center max-w-sm">
        <div className="flex justify-center mb-4">
          <FaTimesCircle className="text-red-500 text-6xl" />
        </div>
        <h1 className="text-3xl font-bold text-gray-800 mb-2">
          Payment Failed
        </h1>
        <p className="text-gray-600 mb-6">
          Unfortunately, your payment could not be processed. Please try again.
        </p>
        <button
          onClick={() => (window.location.href = "/user-page")}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
        >
          Retry Payment
        </button>
      </div>
    </div>
  );
}
