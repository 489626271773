import React, { useState, useEffect, useRef } from "react";
import {
  FaPaperclip,
  FaInfoCircle,
  FaTimes,
  FaMicrophone,
  FaArrowRight,
  FaStop,
} from "react-icons/fa";
import { encode } from "gpt-tokenizer";
import Gcip from "../../../utils/gcip";
import api from "../../../utils/api";
import { useAuth } from "../../../utils/AuthProvider";
import AudioRecorder from "../../../utils/AudioRecorder";
import { recorderToolkit } from "../utils/recorderHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MessageInput = React.memo(
  ({
    userLevel,
    newMessage,
    setNewMessage,
    handleSendMessage,
    handleFileChange,
    fileInputRef,
    handleUpload,
    setModalOpen,
    messageWordCount,
    isLoading,
    setIsLoading,
    wait,
    setWait,
    runId,
    threadId,
    isRecording,
    setIsRecording,
    isFetchingSpeech,
    setIsFetchingSpeech,
    speechToPrompt,
    setSpeechToPrompt,
    eventSourceRef,
    setLinkSearch,
    setDidSearch,
    setMessages,
    darkMode,
  }) => {
    const { token } = useAuth;

    const [showInfo, setShowInfo] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [tokenCount, setTokenCount] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showExceededWarning, setShowExceededWarning] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const textRef = useRef(null);

    const onRecordingComplete = async (blob) => {
      setIsRecording(false);
      setIsFetchingSpeech(true);

      const url = URL.createObjectURL(blob);

      try {
        const formData = new FormData();
        formData.append("file", blob, "audio.wav");

        const response = await api.post("/chat/speech", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        const transcribedText = response.data.text;
        setNewMessage(transcribedText);

        if (speechToPrompt && transcribedText) {
          await handleSendMessage(transcribedText, null, "user", 0);
        }

        setIsFetchingSpeech(false);
      } catch (error) {
        console.log(error);
      }

      //console.log(blob, url);
    };

    const handleInputChange = (e) => {
      setNewMessage(e.target.value);
      textRef.current.style.height = "auto";
      textRef.current.style.height = `${Math.min(
        textRef.current.scrollHeight,
        125
      )}px`;
      updateTokenCount(e.target.value);
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      const maxFileSize = 25 * 1024 * 1024;

      if (file && file.size <= maxFileSize) {
        setSelectedFile(file);
        handleFileChange(event);
      } else if (file.size > maxFileSize) {
        setShowErrorModal(true);
        event.target.value = null;
      }
    };
    const onSendClick = () => {
      if (isLoading || wait || isRecording) {
        return;
      }
      if (
        newMessage.trim() !== "" &&
        (messageWordCount <= 5000 ||
          userLevel === "premium" ||
          userLevel === "employee")
      ) {
        setNewMessage("");
        setSelectedFile(null);
        handleSendMessage(newMessage, selectedFile, "user", tokenCount);
        fileInputRef.current.value = null;
        textRef.current.style.height = "50px";
      }
    };

    const updateTokenCount = (message) => {
      const tokens = encode(message);
      setTokenCount(tokens.length);
    };

    const handleCancel = async () => {
      try {
        // Close the event source if active
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
        }

        // If no `runId` exists, handle cancellation locally
        if (!runId) {
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];

            // Keep the current message content as is
            updatedMessages[updatedMessages.length - 1] = {
              ...updatedMessages[updatedMessages.length - 1],
              isAnalyzing: false, // Stop analyzing state
            };

            // Save to localStorage
            localStorage.setItem(
              `messages_${threadId}`,
              JSON.stringify(updatedMessages)
            );
            return updatedMessages;
          });

          setWait(false);
          setIsLoading(false);
          return;
        }

        // Call API to cancel server-side processing
        const response = await api.post(
          `/chat/stream/cancel/${threadId}/run/${runId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);

        // Update messages to reflect cancellation without adding additional notes
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];

          // Retain current content and stop analyzing state
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            isAnalyzing: false,
          };

          // Save to localStorage
          localStorage.setItem(
            `messages_${threadId}`,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      } catch (error) {
        console.error(error);
      } finally {
        // Reset state  of success or failure
        setWait(false);
        setIsLoading(false);
        setDidSearch(false);
        setLinkSearch(false);
      }
    };

    useEffect(() => {
      setShowExceededWarning(messageWordCount >= 5000);
    }, [messageWordCount]);

    return (
      <div
        className={`pt-4 px-4 shadow-md border-t transition-colors duration-200 
    bg-gradient-to-r from-blue-50 to-green-50 dark:from-gray-800 dark:to-gray-900 
    border-gray-200 dark:border-gray-700`}
      >
        {showExceededWarning && userLevel === "free" && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex items-center">
            <span className="block sm:inline text-xs font-medium">
              ⚠️ You've reached the 5000-token limit for your free account. To
              continue the conversation without interruptions, please{" "}
              <span
                className="cursor-pointer text-blue-600 underline"
                onClick={() => setModalOpen(true)}
              >
                upgrade to our premium plan.
              </span>
            </span>
          </div>
        )}
        {isRecording && recorderToolkit({ isRecording })}

        <div className="flex items-center justify-center">
          {isRecording ? (
            <AudioRecorder
              onRecordingComplete={(blob) => {
                setIsRecording(false);
                onRecordingComplete(blob);
              }}
              startButtonText="Start Recording"
              stopButtonText="Stop Recording"
              playButtonText="Play Recording"
              buttonStyles={{ backgroundColor: "green", color: "white" }}
              audioStyles={{ marginBottom: "10px" }}
            />
          ) : (
            <div className="relative w-full sm:max-w-auto">
              <textarea
                ref={textRef}
                className={`w-full p-3 mb-1 rounded-lg focus:outline-none focus:ring-2 resize-none text-sm pr-10 shadow-inner transition-all duration-200 
                bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 placeholder-gray-500 dark:placeholder-gray-300 dark:border-[1px] dark:border-gray-400
                ${
                  showExceededWarning && userLevel === "free"
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-green-400 dark:focus:ring-green-600"
                }`}
                placeholder={
                  !isFetchingSpeech ? "Type your message here... 💬" : ""
                }
                value={newMessage}
                onChange={handleInputChange}
                onPaste={(e) => {
                  e.preventDefault();
                  let text = (e.clipboardData || window.clipboardData).getData(
                    "text"
                  );
                  text = text.replace(/\n\s*\n/g, "");
                  const cursorPosition = e.target.selectionStart;
                  const value = e.target.value;
                  e.target.value = value.slice(0, cursorPosition) + text;
                  handleInputChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey && !isLoading) {
                    e.preventDefault();
                    onSendClick();
                  }
                }}
                rows="1"
                style={{ maxHeight: "125px", overflow: "auto" }}
                disabled={
                  (showExceededWarning && userLevel === "free") ||
                  isLoading ||
                  isFetchingSpeech
                }
              />

              {isFetchingSpeech && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="animate-spin h-7 w-7"
                  />
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={onFileChange}
                accept=".pdf,.docx,.pptx,.txt,.xlsx"
              />
              <button
                type="button"
                className="absolute top-[14px] right-3 sm:right-4 pb-1 flex items-center text-gray-500 dark:text-gray-300 hover:text-green-500"
                onClick={handleUpload}
                disabled={isFetchingSpeech}
                title="File Upload"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <FaPaperclip size={18} />
              </button>
              {showTooltip && (
                <div className="absolute bottom-10 right-2 sm:right-4 bg-gray-700 text-white text-xs p-2 rounded-md shadow-md">
                  {userLevel !== "premium" && userLevel !== "employee"
                    ? "This feature is available for premium users only."
                    : "Files larger than 25 MB cannot be uploaded."}
                </div>
              )}
            </div>
          )}

          <button
            className={`px-3 ml-2 text-white rounded-lg w-auto h-10 text-sm relative mb-2 dark:-bg-gray-800 ${
              wait
                ? "bg-red-400 hover:bg-red-500 shadow"
                : isRecording
                ? ""
                : isLoading && !wait
                ? "bg-gray-500"
                : isFetchingSpeech
                ? "bg-gray-400"
                : "bg-green-400 dark:bg-green-700 hover:bg-green-500 shadow"
            }`}
            onClick={() => {
              if (wait) {
                handleCancel();
              } else if (
                !newMessage.trim() &&
                !selectedFile &&
                userLevel === "premium"
              ) {
                setIsRecording(!isRecording);
              } else {
                onSendClick();
              }
            }}
            title="Send/Stop Button"
            disabled={
              (showExceededWarning && userLevel === "free") ||
              isFetchingSpeech ||
              isLoading
            }
          >
            {wait ? (
              <FaStop size={12} />
            ) : isLoading && !wait ? (
              <div className="bg-gray-500 rounded-lg shadow-md flex justify-center">
                <FaArrowRight size={14} />
              </div>
            ) : isRecording ? (
              <div className="bg-green-500 px-2 py-1 sm:px-3 sm:py-2 rounded-lg shadow-md flex justify-center">
                <span className="text-white text-xs sm:text-sm font-medium">
                  Back to Chat
                </span>
              </div>
            ) : newMessage.trim() === "" &&
              !selectedFile &&
              (userLevel === "premium" || userLevel === "employee") ? (
              <FaMicrophone size={14} />
            ) : (
              <FaArrowRight size={14} />
            )}
          </button>
        </div>

        {selectedFile && (
          <div className="flex items-center my-2 text-sm text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-700 p-2 rounded-lg shadow-inner">
            <p className="mr-2">📁 {selectedFile.name}</p>
            <FaTimes
              className="text-red-500 cursor-pointer hover:text-red-600"
              onClick={() => {
                setSelectedFile(null);
                fileInputRef.current.value = null;
              }}
            />
          </div>
        )}

        <div className="flex items-center justify-center text-xs text-gray-600 mb-1">
          <FaInfoCircle
            className="mr-2 text-gray-500 dark:text-gray-100  hover:text-green-500 cursor-pointer"
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
          />
          <p className="text-center dark:text-white">
            Beth AI might slip up—follow the Prompt Guide or talk to a human for
            help!{" "}
          </p>

          {showInfo && <Gcip />}
        </div>

        {showErrorModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-2xl font-bold text-red-500 mb-4">Error</h2>
              <p className="mb-4">
                File size exceeds 25 MB. Please upload a smaller file.
              </p>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                onClick={() => setShowErrorModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default MessageInput;