import React, { useState } from "react";
import Modal from "react-modal";
import api from "../../../utils/api.js";
import { useAuth } from "../../../utils/AuthProvider.jsx";
import "../../../styles/small_loader.css";
import { toast, ToastContainer } from "react-toastify";
import Maintenance from "../utils/Maintenance.jsx";
import Billing from "./Billing.jsx";
import PlanModal from "../utils/PlanModal.js";
Modal.setAppElement("#root");

export default function Payments({ userLevel, modalOpen, closeModal }) {
  const [paymentModal, setPaymentModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMt, setModalMt] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const [appliedDiscount, setAppliedDiscount] = useState(null); // State to store the applied discount
  const [discountCode, setDiscountCode] = useState("");
  const [statusMessage, setStatusMessage] = useState(""); // State for feedback

  const { token } = useAuth();

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const openPaymentModal = () => {
    closeModal();
    setPaymentModal(true);
  };

  const openMt = () => {
    setModalMt(true);
  };
  const closeMt = () => {
    setModalMt(false);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setAppliedDiscount(null);
  };

  // Check if the user is already subscribed
  const checkSubscription = async (token) => {
    try {
      const response = await api.get(`/user/level`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (response.data.user_level === "premium") {
          console.log("User is already subscribed to BETH Premium.");
          return true;
        }
      } else {
        console.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubscribe = async (e) => {
    setLoading(true);

    e.preventDefault();
    const email = document.getElementById("email").value;
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const discountCode = document.getElementById("discountCode").value;
    const phone = document
      .getElementById("phoneNumber")
      .value.replace(/\s+/g, "");

    if (!phone) {
      alert("Phone number is required!");
      return;
    } else if (phone.length !== 14) {
      alert("Enter a valid phone number! ");
      return;
    }
    const createCustomerRequest = {
      token: token,
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      discount_code: discountCode || "",
    };

    try {
      const isSubscribed = await checkSubscription(token);
      if (isSubscribed === true) {
        toast.warning("You are already subscribed to BETH Premium.");
        return;
      } else {
        setIsButtonDisabled(true);
        setLoading(true);

        const createCustomerResponse = await api.post(
          "/payment/create/customer",
          createCustomerRequest,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (createCustomerResponse.status === 200) {
          const customerId = createCustomerResponse.data.customer.id;
          sessionStorage.setItem("customerId", customerId);

          let createSubscriptionResponse;
          // Annual Plan
          if (selectedPlan === "annual") {
            createSubscriptionResponse = await api.post(
              "/payment/create/plan",
              {
                customer_id: customerId,
                plan_name: "premium_annual",
                discount_code: appliedDiscount?.code || null,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          // Monthly Plan
          else if (selectedPlan === "monthly") {
            createSubscriptionResponse = await api.post(
              "/payment/create/plan",
              {
                customer_id: customerId, // Using customerId from the current scope
                plan_name: "premium",
                discount_code: appliedDiscount?.code || null,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            throw new Error("Invalid plan selected");
          }

          // Handle the redirection if the subscription is successfully created
          if (createSubscriptionResponse?.status === 200) {
            window.location.href =
              createSubscriptionResponse.data.plan.actions[0].url;
          } else {
            throw new Error("Failed to create subscription");
          }
        } else {
          throw new Error("Failed to create customer");
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "Error Occurred");
      setAppliedDiscount("");
      setDiscountCode("");
      setStatusMessage("");
    } finally {
      setIsButtonDisabled(false);
      setLoading(false);
    }
  };

  return (
    <>
      <PlanModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        userLevel={userLevel}
        openPaymentModal={openPaymentModal}
      />

      <Modal
        isOpen={paymentModal}
        onRequestClose={closePaymentModal}
        contentLabel="Payment Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-2xl w-full relative max-h-full overflow-y-auto scrollbar-hidden">
          <button
            onClick={closePaymentModal}
            className="absolute top-4 right-4 text-gray-600 dark:text-gray-200 hover:text-gray-800 hover:dark:text-gray-400 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <Billing
            userLevel={userLevel}
            closeModal={closeModal}
            loading={loading}
            handleSubscribe={handleSubscribe}
            handlePhoneChange={handlePhoneChange}
            isButtonDisabled={isButtonDisabled}
            phoneNumber={phoneNumber}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            appliedDiscount={appliedDiscount}
            setAppliedDiscount={setAppliedDiscount}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            statusMessage={statusMessage}
            setStatusMessage={setStatusMessage}
          ></Billing>
        </div>
      </Modal>
      <Maintenance isOpen={modalMt} onRequestClose={closeMt} />

      <ToastContainer />
    </>
  );
}
