import Scroll from "./Scroll";
import ScrollPartners from "./ScrollPartners";

export default function Partners() {
  return (
    <section className="py-16 shadow-lg">
      <h2 className="text-4xl font-extrabold text-center text-black mb-4">
        Our Trusted <span className="text-green-500">Partners</span>
      </h2>
      <div className="flex flex-wrap justify-center gap-8">
        <ScrollPartners />
      </div>
    </section>
  );
}
