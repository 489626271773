import React, { useState, useEffect } from "react";

export default function Survey({ token }) {
  const [surveyOpen, setSurveyOpen] = useState(false);

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        setSurveyOpen(true);
      }, 500);
    }
  }, [token]);

  const closeSurvey = () => setSurveyOpen(false);

  if (!surveyOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-500 opacity-100 delay-150">
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity duration-500 delay-150"
        onClick={closeSurvey}
      ></div>

      {/* Modal */}
      <div className="relative bg-gradient-to-r from-white to-gray-50 rounded-xl p-6 sm:p-10 w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 max-w-lg mx-auto shadow-2xl transform transition-all duration-500 ease-in-out scale-95 hover:scale-105 text-center">
        <button
          onClick={closeSurvey}
          className="absolute top-4 right-8 mt-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <div className="flex flex-col items-center mb-4">
          <div className="w-12 h-12 bg-green-100 text-green-600 rounded-full flex items-center justify-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-1">
            We Value Your Opinion
          </h2>
        </div>

        <p className="text-gray-600 mb-6">
          Help us shape your BETH experience by taking this survey. Your
          feedback is invaluable, and we’d love to hear your thoughts.
          <br />
          <br />
          We appreciate your time and feedback.
          <br />
          <span className="font-bold">-BETH</span>
        </p>

        <button
          onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfd6nJvRoC1169ybhjWeEI8hDwAS4973O5TW9t3CBBwI4_W1A/viewform", "_blank")}
          className="inline-flex items-center px-8 py-3 text-white bg-green-400 rounded-full shadow-md hover:bg-green-500 focus:ring-4 focus:ring-blue-300 transform transition duration-200 hover:scale-105"
        >
          <span className="mr-2">Take Survey</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
