import React from "react";
import {
  FiMail,
  FiCheckCircle,
  FiEdit,
  FiPlay,
  FiArrowLeft,
} from "react-icons/fi";
import { FaRocket } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../utils/navigation";

const pageVariants = {
  initial: { opacity: 0, y: 50 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
};

export default function EmailResponse() {
  const navigate = useNavigate();

  const handleCall = () => {
    scrollToTop();
    navigate("/signup");
  };
  return (
    <AnimatePresence>
      <motion.div
        className="bg-gradient-to-br from-blue-50 via-indigo-100 to-blue-50 min-h-screen flex flex-col items-center text-gray-800 font-sans"
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
        transition={{ duration: 0.5 }}
      >
        <header className="w-full py-8 px-8 bg-gradient-to-r from-blue-400 to-indigo-600 text-white text-center shadow-lg">
          <h1 className="text-4xl font-extrabold tracking-wide">
            Quick Email Response
          </h1>
          <p className="mt-3 text-lg font-light max-w-2xl mx-auto">
            B.E.T.H. can craft tailored messages for business proposals, tax
            inquiries, pitch decks, or any accounting-related communication you
            need.
          </p>
        </header>
        <main className="max-w-5xl w-full flex flex-col items-center px-6 mt-12 space-y-16">
          <section className="text-center space-y-4 flex flex-col items-center">
            <h2 className="text-3xl font-semibold text-gray-700 flex items-center justify-center">
              Time-Saving, Improved Communication, Increased Productivity
            </h2>
            <p className="text-gray-600 leading-relaxed max-w-2xl mx-auto">
              Whether it’s reaching out to clients, partners, or colleagues,
              B.E.T.H. ensures you get the right words effortlessly.
            </p>
            <motion.button
              className="mt-6 px-8 py-3 bg-indigo-600 text-white rounded-full font-semibold hover:bg-indigo-700 transition duration-300 shadow-lg transform hover:scale-105 flex items-center justify-center"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleCall()}
            >
              Sign up for free <FaRocket className="ml-3" />
            </motion.button>
          </section>

          <section className="w-full text-center px-4 space-y-12">
            <h3 className="text-2xl font-semibold text-gray-700">
              How It Works
            </h3>
            <div className="flex flex-col md:flex-row justify-center md:space-x-8 space-y-8 md:space-y-0">
              <motion.div
                className="flex-1 bg-white rounded-lg shadow-lg p-6 transform transition-transform hover:scale-105"
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h4 className="text-lg font-semibold text-indigo-600 flex items-center justify-center space-x-2">
                  <FiEdit className="mr-2" /> Input
                </h4>
                <p className="text-gray-600 mt-2">
                  Type your message or business communication details.
                </p>
              </motion.div>
              <motion.div
                className="flex-1 bg-white rounded-lg shadow-lg p-6 transform transition-transform hover:scale-105"
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h4 className="text-lg font-semibold text-indigo-600 flex items-center justify-center space-x-2">
                  <FiCheckCircle className="mr-2" /> Generate
                </h4>
                <p className="text-gray-600 mt-2">
                  B.E.T.H. crafts a tailored, professional message in seconds.
                </p>
              </motion.div>
              <motion.div
                className="flex-1 bg-white rounded-lg shadow-lg p-6 transform transition-transform hover:scale-105"
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h4 className="text-lg font-semibold text-indigo-600 flex items-center justify-center space-x-2">
                  <FiMail className="mr-2" /> Customize
                </h4>
                <p className="text-gray-600 mt-2">
                  Easily edit and personalize the response to suit your needs.
                </p>
              </motion.div>
            </div>
          </section>

          <section className="w-full text-center space-y-4">
            <h3 className="text-2xl font-semibold text-gray-700 flex items-center justify-center">
              Instructional Video <FiPlay className="ml-2 text-indigo-500" />
            </h3>
            <div className="relative w-full h-full bg-gray-100 rounded-lg shadow-lg flex items-center justify-center overflow-hidden">
              <video
                src="https://bethaibucket.s3.us-east-1.amazonaws.com/videos/QUICK%20EMAIL%20RESPONSE.mp4"
                controls
                className="w-full h-full object-fill rounded-lg"
              ></video>
            </div>
          </section>
        </main>
        <aside className="fixed left-6 bottom-6">
          <Link
            to="/features"
            onClick={scrollToTop}
            className=" bg-white rounded-lg shadow-lg p-4 flex items-center space-x-2 hover:bg-gray-100 transition duration-300"
          >
            <FiArrowLeft className="text-gray-600" />
            <span className="text-gray-600">Back to Features</span>
          </Link>
        </aside>

        <footer className="w-full py-8 bg-gradient-to-br from-blue-50 via-indigo-100 to-blue-50 text-gray-600 text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} Babylon by I-Lead. All rights reserved.
          </p>
        </footer>
      </motion.div>
    </AnimatePresence>
  );
}
