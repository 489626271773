import React from "react";
import { FiArrowUpLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utils/navigation";

export default function PrivacyPolicy() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    {
      navigate(-1) || navigate("/");
    }
    setTimeout(() => {
      scrollToTop();
    }, 50);
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-10 px-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl p-8 relative">
        <button
          onClick={handleBackClick}
          className="absolute top-4 right-4 mt-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <div>
          <img
            src="/babylon1.png"
            alt="Logo"
            className="h-16 mb-4 mr-4 sm:mr-6 lg:mr-8"
          />
          <p className="mb-4 text-2xl">
            <strong>Privacy Statement</strong>
          </p>
          <div>
            <p className="mb-4">
              At B.E.T.H. AI Chatbot, accessible from ai.babylon2k.org, your
              privacy and data security are extremely important to us. This
              Privacy Policy explains how we collect, use, and protect your
              personal information when you interact with our services. Please
              read this policy carefully to understand our practices.
            </p>
            <p className="mb-4">
              To learn more about the types of personal information that we
              collect, how we collect, use, process and update, protect, share
              and dispose, you may refer to our Privacy Statement. We highly
              encourage you to provide us any feedback on our privacy statement
              and notices.
            </p>

            <ol className="mb-4 list-decimal pl-5">
              <li>
                <strong>Information We Collect</strong>
                <p>We collect and process the following information:</p>
                <ol className="list-inside pl-5">
                  <li>
                    <strong>1.1 Personal Information</strong>
                    <ul className="list-disc pl-5">
                      <li>
                        <strong>Account Details</strong>: When you register or
                        interact with the chatbot, we may collect your name,
                        email address, and other relevant contact details.
                      </li>
                      <li>
                        <strong>Usage Data</strong>: We collect information
                        about your interactions with our chatbot and website,
                        including IP address, browser type, location, and
                        interaction history.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>1.2 Financial and Compliance Queries</strong>
                    <ul className="list-disc pl-5">
                      <li>
                        The B.E.T.H. AI Chatbot processes the questions and
                        queries you submit, which may include information
                        regarding tax, accounting, audit, and licensing. While
                        this data may contain sensitive business or financial
                        information, we use it solely for generating reports and
                        identifying popular topics to improve our services and
                        address the most relevant issues for our users. We do
                        not use this data for any other purposes, and all
                        information is treated with strict confidentiality.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>1.3 Uploaded Files</strong>
                    <ul className="list-disc pl-5 mb-5">
                      <li>
                        Uploaded files are encrypted and securely stored in our
                        system, ensuring they cannot be accessed by anyone,
                        including our team. Once uploaded, your files remain
                        completely private and protected with the highest level
                        of security and confidentiality.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>

              <li>
                <strong>How We Use Your Information</strong>
                <p>We use your information for the following purposes:</p>
                <ul className="list-disc pl-5 mb-5">
                  <li>
                    To provide personalized and accurate answers to your tax,
                    accounting, audit, and compliance-related queries.
                  </li>
                  <li>
                    To improve the overall functionality and user experience of
                    the chatbot.
                  </li>
                  <li>
                    To analyze usage and trends to enhance our platform's
                    performance.
                  </li>
                  <li>
                    To communicate updates or important notices (only with your
                    consent).
                  </li>
                </ul>
              </li>

              <li>
                <strong>How We Protect Your Information</strong>
                <p>
                  We prioritize the security of your data through various means:
                  :
                </p>
                <ul className="list-disc pl-5 mb-5">
                  <li>
                    <strong>Data Encryption</strong>: All information, including
                    any uploaded files, is encrypted during transmission (using
                    SSL/TLS) and at rest. This ensures that no unauthorized
                    third parties can access your data while it's being
                    transmitted or stored.
                  </li>
                  <li>
                    <strong>Secure Storage</strong>: All data, including
                    personal information and uploaded files, is stored securely
                    on our servers. We employ industry-standard security
                    practices to ensure that your data is protected from
                    unauthorized access, alteration, or disclosure.
                  </li>
                  <li>
                    <strong>Limited Access to Uploaded Files</strong>: Even we,
                    the developers and administrators of B.E.T.H. AI Chatbot, do
                    not have access to the files you upload. These files are
                    securely encrypted, and only you, through your session with
                    the chatbot, can access or view them. We have implemented
                    strict security protocols and encryption methods to ensure
                    this level of privacy.
                  </li>
                  <li>
                    <strong>Regular Security Audits</strong>: We conduct regular
                    security reviews and audits of our systems to ensure the
                    ongoing protection of your data. Our infrastructure follows
                    best practices for server management and data security.
                  </li>
                </ul>
              </li>

              <li>
                <strong>Data Sharing and Disclosure</strong>
                <p>
                  We do not sell, trade, or rent your personal information to
                  third parties. We may share your data under the following
                  circumstances:
                </p>
                <ul className="list-disc pl-5 mb-5">
                  <li>
                    <strong>With Service Providers:</strong>: To help us operate
                    our platform (e.g., hosting and server management), but only
                    under strict confidentiality agreements.
                  </li>
                  <li>
                    <strong>For Legal Compliance</strong>: If required by law,
                    legal processes, or governmental requests, or to protect the
                    rights, safety, and property of our users and platform.
                  </li>
                </ul>
              </li>

              <li>
                <strong>Cookies and Tracking Technologies</strong>
                <p className="mb-5">
                  We use cookies and similar technologies to improve your
                  experience on our platform. These cookies help us analyze how
                  users interact with the chatbot and ensure its proper
                  functionality. You may opt to disable cookies through your
                  browser settings, but doing so may affect the quality of your
                  experience on our platform.
                </p>
              </li>

              <li>
                <strong>Data Retention</strong>
                <p className="mb-5">
                  We retain your personal information only as long as necessary
                  to fulfill the purposes outlined in this Privacy Policy or as
                  required by law. Uploaded files and data are stored securely
                  and are automatically deleted when they are no longer needed
                  for the chatbot interaction or at your request.
                </p>
              </li>

              <li>
                <strong>Your Rights</strong>
                <p>You have the right to:</p>
                <ul className="list-disc pl-5 mb-5">
                  <li>Access the personal data we hold about you.</li>
                  <li>Correct or update your personal information.</li>
                  <li>Request the deletion of your personal data.</li>
                  <li>
                    Withdraw your consent for any data processing activities
                    based on your consent.
                  </li>
                  <p className="mt-3">
                    To exercise these rights, please contact us at
                    <strong> ask@babylon2k.org</strong>
                  </p>
                </ul>
              </li>

              <li>
                <strong>Changes to this Privacy Policy</strong>
                <p className="mb-5">
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page, and the updated date will
                  be reflected at the top of this policy. We encourage you to
                  review this policy periodically for any updates.
                </p>
              </li>

              <li>
                <strong>Contact Us</strong>
                <p>
                  If you have any questions about this Privacy Policy or how
                  your data is handled, please contact us at:
                </p>
              </li>
            </ol>
            <div>
              <p className="font-bold">B.E.T.H. AI Chatbot</p>
              <p>
                Email: <strong>ask@babylon2k.org</strong>
              </p>
              <p>
                Website <strong>ai.babylon2k.org</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
