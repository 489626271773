import PhoneInput from "react-phone-input-2";
import { ReactComponent as Loader } from "../../../assets/loader.svg";
import api from "../../../utils/api";
import { useEffect, useState, useRef } from "react";
import BillingForm from "../utils/BillingForm";

export default function Billing({
  loading,
  handleSubscribe,
  handlePhoneChange,
  isButtonDisabled,
  phoneNumber,
  selectedPlan,
  setSelectedPlan,
  appliedDiscount,
  setAppliedDiscount,
  discountCode,
  setDiscountCode,
  statusMessage,
  setStatusMessage,
}) {
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await api.get(`/discount/all`);

        const discountsArray = Array.isArray(response)
          ? response
          : response.data;
        setDiscounts(discountsArray);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };

    fetchDiscounts();
  }, []);

  const handleApply = async () => {
    if (!discountCode) {
      setStatusMessage("Please enter a discount code.");
      setAppliedDiscount(null); // Reset the discount
      return;
    }

    const currentDate = new Date();

    const matchedDiscount = discounts.find(
      (discount) =>
        discount.code === discountCode &&
        discount.is_active &&
        new Date(discount.valid_from) <= currentDate &&
        new Date(discount.valid_to) >= currentDate
    );

    if (!matchedDiscount) {
      setStatusMessage("This discount code is invalid, inactive, or expired.");
      setAppliedDiscount(null);
      return;
    }

    try {
      const response = await api.get(`/discount/${discountCode}/details`);
      setStatusMessage(
        `Discount applied successfully! You saved ${matchedDiscount.value}%`
      );
      setAppliedDiscount(matchedDiscount);
    } catch (error) {
      console.error("Error applying discount:", error);
      setStatusMessage("Error applying discount. Please try again.");
      setAppliedDiscount(null);
    }
  };

  const planDetails =
    selectedPlan === "monthly"
      ? { price: 16.99, label: "BETH Premium - Monthly", term: "per month" }
      : { price: 203.88, label: "BETH Premium - Annually", term: "per year" };

  // const discountedPrice =
  //   selectedPlan === "annual"
  //     ? planDetails.price * (1 - 0.2)
  //     : planDetails.price;

  // const discountEarly = 2.0;
  return (
    <form
      onSubmit={handleSubscribe}
      className="max-w-xl mx-auto mt-10 bg-white shadow-lg rounded-lg p-6 text-gray-800 dark:bg-gray-800 dark:text-gray-200"
    >
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200">
          Subscribe to BETH Premium
        </h2>
        <p className="text-gray-500 mt-2 dark:text-gray-400">
          {selectedPlan === "monthly" ? "Billed monthly" : "Billed annually"}
        </p>
      </div>

      {/* Plan Selection */}
      <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg mb-6">
        <h3 className="text-lg font-bold text-gray-700 dark:text-gray-200 mb-4">
          Choose Your Plan
        </h3>
        <div className="flex justify-center space-x-4">
          <button
            type="button"
            className={`px-4 py-2 rounded-lg text-lg font-semibold border-2 ${
              selectedPlan === "monthly"
                ? "bg-green-500 text-white"
                : "border-gray-300 dark:border-gray-600"
            }`}
            onClick={() => setSelectedPlan("monthly")}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-lg text-lg font-semibold border-2 ${
              selectedPlan === "annual"
                ? "bg-green-500 text-white"
                : "border-gray-300 dark:border-gray-600"
            }`}
            onClick={() => setSelectedPlan("annual")}
          >
            Annual
          </button>
        </div>
      </div>

      {/* Billing Information */}
      <div className="mb-6">
        <h3 className="text-lg font-bold text-gray-700 dark:text-gray-200">
          Billing Information
        </h3>

        <BillingForm
          phoneNumber={phoneNumber}
          handlePhoneChange={handlePhoneChange}
        />

        <div className="mt-4">
          <label
            htmlFor="discountCode"
            className="block text-gray-700 font-semibold mb-2 dark:text-gray-200"
          >
            Discount Code
          </label>
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <input
              type="text"
              id="discountCode"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              className="flex-grow p-3 border border-gray-300 rounded-lg text-gray-800 placeholder-gray-400 dark:text-gray-100 dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-green-500 dark:focus:ring-green-500 focus:outline-none"
              placeholder="Enter discount code"
            />
            <button
              type="button"
              onClick={handleApply}
              className="px-4 py-2 font-semibold text-white bg-green-500 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Apply
            </button>
          </div>
        </div>

        {statusMessage && (
          <p
            className={`mt-2 text-sm ${
              statusMessage.includes("success")
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {statusMessage}
          </p>
        )}
      </div>

      <h3 className="text-lg font-bold text-gray-700 mb-4 dark:text-gray-200">
        Billing Details
      </h3>
      <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg mb-6">
        {/* Plan Details */}
        <div className="flex justify-between py-2">
          <p className="text-gray-700 dark:text-gray-100">
            {planDetails.label}
          </p>
          <p className="font-semibold text-gray-800 dark:text-gray-100">
            ${planDetails.price.toFixed(2)}
          </p>
        </div>

        {/* Discount Section */}
        {appliedDiscount ? (
          <div className="flex justify-between py-2">
            <div className="flex flex-col">
              <p className="text-gray-700 dark:text-gray-100">Discount</p>
              {appliedDiscount.code && (
                <p className="text-xs italic dark:text-gray-300">
                  -{appliedDiscount.code}
                </p>
              )}
            </div>
            <p className="font-semibold text-gray-800 dark:text-gray-100">
              - $
              {((planDetails.price * appliedDiscount.value) / 100).toFixed(2)}
            </p>
          </div>
        ) : (
          <div className="flex justify-between py-2">
            <p className="text-gray-700 dark:text-gray-100">Discount</p>
            <p className="font-semibold text-gray-800 dark:text-gray-100">
              $0.00
            </p>
          </div>
        )}

        {/* Total Due */}
        <div className="flex justify-between py-2 border-t-2 border-gray-300 pt-2 dark:border-gray-600">
          <p className="text-lg font-bold text-gray-800 dark:text-gray-100">
            Total Due
          </p>
          <p className="text-lg font-bold text-green-600 dark:text-green-400">
            $
            {(
              planDetails.price -
              (appliedDiscount
                ? (planDetails.price * appliedDiscount.value) / 100
                : 0)
            ).toFixed(2)}
          </p>
        </div>
      </div>

      <button
        type="submit"
        disabled={isButtonDisabled}
        className={`w-full py-3 font-bold rounded-lg text-white bg-green-500 hover:bg-green-600 transition-colors ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        {loading ? (
          <Loader id="spinner" className="h-6 w-6 mx-auto" />
        ) : (
          "Subscribe"
        )}
      </button>
    </form>
  );
}
