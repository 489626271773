export const getBase64ImageSrc = (base64String) => {
  try {
    const decodedBase64 = atob(base64String);

    if (decodedBase64.startsWith("/9j/")) {
      return `data:image/jpeg;base64,${decodedBase64}`;
    } else {
      return `data:image/png;base64,${decodedBase64}`;
    }
  } catch (error) {
    console.error("Failed to decode base64 string:", error);
    return null;
  }
};
