import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="flex flex-col sm:flex-row justify-between items-center border-t border-gray-600 pt-8">
          {/* Links Section */}
          <div className="flex space-x-6 order-2 mb-4 sm:mb-0">
            <Link
              to="/about-us"
              onClick={scrollToTop}
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              About
            </Link>
            <Link
              to="/privacy"
              onClick={scrollToTop}
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              Privacy
            </Link>
            <Link
              to="/terms"
              onClick={scrollToTop}
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              Terms of Service
            </Link>
            <Link
              to="/pricing"
              onClick={scrollToTop}
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              Pricing
            </Link>
          </div>

          <div className="text-center text-gray-400 order-1 mb-6 sm:mb-0">
            <p>&copy; 2024 Babylon by I-Lead. All rights reserved.</p>
          </div>

          <div className="flex space-x-4 order-3">
            <a
              href="https://www.facebook.com/babyloneth"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://x.com/uhy_mac"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              <FaTwitter size={24} />
            </a>

            <a
              href="https://www.linkedin.com/company/babylon2k"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-200 transition duration-300"
            >
              <FaLinkedin size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
